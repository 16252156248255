import React from "react";
import { useParams } from "react-router-dom";

import Logo from "components/ui/logo";
import AndorLogo from "images/andor-logo-h-light.svg";
import { BaseScreen } from "components/homepage/General";

const urls = {
  oim: "http://www.oimonline.com/",
  northwell: "https://www.northwell.edu/",
  pro: "https://www.proliancesurgeons.com/",
};

export const GenericPage = () => {
  const { tenant } = useParams();
  if (!!tenant && urls[tenant.toLowerCase()]) {
    window.location.replace(urls[tenant.toLowerCase()]);
    return <React.Fragment />;
  } else {
    return (
      <BaseScreen>
        <div className="w-full h-full flex-col justify-start items-center">
          <div className="w-full p-4 flex justify-center items-center">
            <Logo>
              <DefaultLogo />
            </Logo>
          </div>
          <div className="flex-grow w-full mt-20 flex flex-col justify-center items-center">
            <div className="text-grayish-strong text-center p-4 mb-4">
              <h1 className="font-bold" style={{ fontSize: "9rem" }}>
                404
              </h1>
              <h3 className="font-semibold text-on-card text-lg">
                The page you are looking for does not exist.
              </h3>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
};
export default GenericPage;

const DefaultLogo = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-card">
      <img
        alt="Andor Health"
        className="max-w-sm  lg:mx-auto"
        style={{ height: "40px" }}
        src={AndorLogo}
      />
    </div>
  );
};
