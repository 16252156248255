import CircularProgress from "@mui/material/CircularProgress";
import Lottie from "lottie-react";
import React from "react";
import loadingAnimation from "../images/andor-loading.json";
import styled from "styled-components";

export const ShowIf = ({ test, children }) => {
  return test ? children : null;
};
export const HideIf = ({ test, children }) => {
  return !test ? children : null;
};

export const ContentArea = styled.div`
  padding: 0px;
  ${({ theme }) => {
    return `${theme.breakpoints.up("sm")} {
      overflow-y: auto;
    }`;
  }}
  ${({ color }) => {
    return color ? `background-color: ${color};` : "";
  }}
`;

export const ContentAreaFull = ({ children, ...otherProps }) => {
  return (
    <ContentArea shrinkBy={0} {...otherProps}>
      {children}
    </ContentArea>
  );
};

const LoadingAreaFullStyled = styled.div`
  height: 80%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20%;
  > div {
    max-width: 100px;
  }
`;

export const LoadingIndicatorScreen = () => {
  return (
    <LoadingAreaFullStyled>
      <Lottie animationData={loadingAnimation} loop={true} />
    </LoadingAreaFullStyled>
  );
};
