import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import noop from "lodash/noop";
import { isMobileOnly } from "react-device-detect";
import { SelfNameContainer, ParticipantNameContainer } from "./styling";
import useLocalization from "hooks/useLocalization";

import {
  AccountCircleIcon,
  AddCircledIcon,
  DeleteOutlineIcon,
} from "components/ui/icons";
import {
  ThinkAndorButton,
  ButtonWithLoaderOnBrand,
} from "components/ui/buttons";
import { ShowIf } from "components/viewhelpers";

const HomeView = ({
  data,
  onCancel,
  setRenderView,
  dispatch,
  onSendInvite,
  errorMessage,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [t, changeLanguage] = useLocalization();

  const isSubmitDataAvailable = data?.participants.length > 0;

  const handleAddParticipant = (e) => {
    e.preventDefault();
    setRenderView("inviteParticipantView");
  };

  const handleInviteParticipant = async () => {
    const participants = data?.participants.map((participant) => {
      return {
        firstName: participant.firstName,
        lastName: participant.lastName,
        email: participant.email,
        phone: participant.phone,
        relationshipName: participant.relationship,
      };
    });
    setIsSubmitting(true);
    await onSendInvite({
      participants,
    });
    setIsSubmitting(false);
  };

  return (
    <div>
      <div className="flex flex-col">
        <SelfNameContainer>
          <span className="text-gray-500">
            {t("techCheckValidateDevice.self")}
          </span>
        </SelfNameContainer>
        <ShowIf test={data.participants.length > 0}>
          {data?.participants.map((participant, idx) => {
            return (
              <ParticipantNameContainer key={idx}>
                <div>
                  <AccountCircleIcon className="text-brand" />
                  <span>
                    {" "}
                    {participant.firstName} {participant.lastName}
                  </span>
                  &nbsp;&nbsp;
                  <span className="text-gray-500">(Participant)</span>
                </div>
                <div>
                  <DeleteOutlineIcon
                    className="text-red-400 cursor-pointer hover:text-red-500"
                    onClick={() => {
                      dispatch({
                        type: "DELETE",
                        key: "participants",
                        id: participant.id,
                      });
                    }}
                  />
                </div>
              </ParticipantNameContainer>
            );
          })}
        </ShowIf>
        <div className="flex rounded-md bg-gray-100 text-sm py-2 pl-4 my-1 justify-between">
          <span className="text-gray-500">
            {t("techCheckValidateDevice.addParticipant")}
          </span>
          <span className="px-2" onClick={(e) => handleAddParticipant(e)}>
            <AddCircledIcon className="text-brand cursor-pointer hover:text-newBlue-150" />
          </span>
        </div>
        <div className="flex justify-start text-xs font-medium mt-2 mb-4">
          {t("techCheckValidateDevice.autoInvitation")}
        </div>
      </div>
      {errorMessage ? (
        <div className="text-danger text-xs font-medium text-center">
          {errorMessage}
        </div>
      ) : null}
      <div
        className={classnames("flex justify-end py-4", {
          "flex-col": isMobileOnly,
        })}
      >
        <ThinkAndorButton
          className={classnames(
            "flex justify-center text-center text-sm bg-gray-700 mr-2 font-medium",
            {
              "w-full m-1": isMobileOnly,
            }
          )}
          isShowLastMargin={!isMobileOnly}
          onClick={onCancel}
          hover={false}
        >
          {t("techCheckValidateDevice.cancel")}
        </ThinkAndorButton>
        <ButtonWithLoaderOnBrand
          className={classnames(
            "flex justify-center text-center text-sm font-medium",
            {
              "w-full m-1": isMobileOnly,
            }
          )}
          type="submit"
          onClick={handleInviteParticipant}
          isSubmitting={isSubmitting}
          disabled={!isSubmitDataAvailable}
          style={{ minWidth: 83, height: 36 }}
        >
          {t("techCheckValidateDevice.submit")}
        </ButtonWithLoaderOnBrand>
      </div>
    </div>
  );
};

export default HomeView;

HomeView.propType = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  setRenderView: PropTypes.func,
  dispatch: PropTypes.func,
  onSendInvite: PropTypes.func,
  selfName: PropTypes.func,
  errorMessage: PropTypes.string,
};

HomeView.defaultProps = {
  onCancel: noop,
  onSendInvite: noop,
};
