import "react-phone-number-input/style.css";

import PhoneInputBase from "react-phone-number-input";
import styled from "styled-components";

export const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const CheckboxText = styled.span`
  a {
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const SquareCheckboxSpan = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;

  border-color: ${({ error }) => {
    return error ? `rgb(var(--color-danger))` : `rgb(var(--color-on-input))`;
  }};
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid rgb(var(--color-on-brand));
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  ${CheckboxInput}:checked + &:after {
    display: block;
  }
  ${CheckboxInput}:checked + & {
    background-color: rgb(var(--color-brand));
  }
`;

export const CheckboxSpan = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
  border-radius: 9999px;
  border-color: ${({ error }) => {
    return error ? `rgb(var(--color-danger))` : `rgb(var(--color-on-input))`;
  }};
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid rgb(var(--color-on-brand));
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  ${CheckboxInput}:checked + &:after {
    display: block;
  }
  ${CheckboxInput}:checked + & {
    background-color: rgb(var(--color-brand));
  }
`;
export const RadioInput = styled.input.attrs({ type: "radio" })`
  height: 0;
  width: 0;
`;

export const OptionRoot = styled.div``;
export const OptionArrowRoot = styled.div``;

export const RadioInputText = styled.span``;

export const RadioInputSpan = styled.span`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
  border-radius: 9999px;
  border-color: ${({ error }) =>
    error ? "rgb(var(--color-danger))" : "rgb(var(--color-on-input))"};
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  ${RadioInput}:focus + &:before {
    display: block;
    background-color: rgba(var(--color-on-card), 0.5);
    border-radius: 1px;
  }

  ${RadioInput}:checked + & {
    background-color: #08d2d2;
    background-color: rgb(var(--color-brand));
  }
`;

export const DatePickerContainer = styled.div`
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 8px;
  }
`;

export const getBorderColor = (props) => {
  if (props.error && !props.inviteparticipant) {
    return "border-danger";
  } else if (!props.error && !props.inviteparticipant) {
    return "border-gray-500";
  } else if (props.error && props.inviteparticipant) {
    return "border-danger";
  } else if (!props.error && props.inviteparticipant) {
    return "border-0";
  }
};

export const getShared = (props) => {
  const borderColor = getBorderColor(props);
  return `${borderColor}`;
};

export const PhoneStyled = styled(PhoneInputBase)(
  ({ isgreybackground }) => `
  background-color: ${isgreybackground === "true" ? "#f4f5f7" : "#ffffff"};
  .PhoneInputInput {
    height: 35px;
    background: ${isgreybackground === "true" ? "#f4f5f7" : "#ffffff"};
    border-radius: 5px;
    :focus-visible {
      outline: none;
    }
  }
  .PhoneInputCountrySelect {
    background: ${isgreybackground === "true" ? "#f4f5f7" : "#ffffff"};
  }
  .PhoneInputCountry {
    background: ${isgreybackground === "true" ? "#f4f5f7" : "#ffffff"};
  }
  .PhoneInputCountrySelectArrow {
    display: block;
    content: "";
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-top: 0 solid #000000;
    border-bottom: 1px solid #000000;
    border-left: 0 solid #000000;
    border-right: 1px solid #000000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    opacity: 0.45;
  }
`
);
