import "react-phone-number-input/style.css";

import React, { useEffect, useState } from "react";

import PhoneInputBase from "react-phone-number-input";
import moment from "moment";
import noop from "lodash/noop";
import styled from "styled-components";

const getBorderColor = (props) =>
  props.error ? "border-danger" : "border-gray-500";

const getShared = (props) => {
  const borderColor = getBorderColor(props);
  return `${borderColor}`;
};

export const Group = (props) => {
  return <div className="mb-2" {...props} />;
};

export const Label = (props) => {
  return (
    <label className="block mb-2 text-sm font-bold text-on-card" {...props} />
  );
};

export const Input = (props) => {
  const shared = getShared(props);
  const value = props.value || "";
  const { error, ...inputProps } = props;
  return (
    <input
      className={`disabled:opacity-50 shadow appearance-none border ${shared} rounded w-full py-2 px-3 text-on-input mb-3 leading-tight focus:outline-none focus:shadow-outline`}
      {...inputProps}
      value={value}
    />
  );
};

export const DatePicker = (props) => {
  const shared = getShared(props);
  const value = props.value || "";
  const { error, ...inputProps } = props;
  return (
    <input
      type="date"
      className={`bg-card disabled:opacity-50 shadow appearance-none border ${shared} rounded w-full py-2 px-3 text-on-input mb-3 leading-tight focus:outline-none focus:shadow-outline`}
      {...inputProps}
      value={value}
    />
  );
};

const PhoneStyled = styled(PhoneInputBase)`
  .PhoneInputInput {
    height: 35px;
  }
`;
export const Phone = (props) => {
  const shared = getShared(props);
  const value = props.value || "";
  const { error, onChange = noop, ...inputProps } = props;
  return (
    <PhoneStyled
      country={"US"}
      defaultCountry={"US"}
      onChange={(val) => {
        val = val || "";
        onChange(val);
      }}
      countryOptionsOrder={["US", "CA", "..."]}
      className={`bg-white disabled:opacity-50 shadow appearance-none border ${shared} rounded w-full px-3 text-on-input mb-3 leading-tight focus:outline-none focus:shadow-outline`}
      {...inputProps}
      value={value}
    />
  );
};

export const Select = ({ children, ...props }) => {
  const shared = getShared(props);
  const value = props.value || "";
  const { error, ...inputProps } = props;
  return (
    <div className="relative inline-block w-full">
      <select
        className={`block appearance-none w-full bg-white border ${shared} hover:border-gray-500 text-on-input px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline`}
        {...inputProps}
      >
        {children}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-on-input">
        <svg
          className="w-4 h-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export const Error = ({ children, ...props }) => {
  return (
    <p className="text-xs italic text-danger" {...props}>
      {children}
    </p>
  );
};

export const BasicDatePicker = ({ onChange = noop, ...props }) => {
  const shared = getShared(props);
  const momentValue = props.value ? moment(props.value, "YYYY-MM-DD") : null;
  const value = momentValue
    ? {
        day: momentValue.date(),
        month: momentValue.month() + 1,
        year: momentValue.year(),
      }
    : {
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      };
  const [date, setDate] = useState({ ...value });
  const { className, error, disabled, ...inputProps } = props;
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const days = range(1, 31, 1);
  const years = range(currentYear, currentYear - 150, -1);

  const [isError, setError] = useState(false);

  const handleChange = (date) => {
    const dateFormat = moment(
      `${date.year}-${date.month}-${date.day}`,
      "YYYY-MM-DD"
    );
    setDate(date);

    onChange(dateFormat.format("YYYY-MM-DD"), false);

    if (dateFormat.isValid()) {
      if (dateFormat > new Date()) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    handleChange(date);
  }, []);
  return (
    <div
      className={` ${shared} justify-between flex-row flex text-on-card bg-card`}
    >
      <div className="flex w-1/3 pr-2">
        <Select
          value={date.month}
          onBlur={noop}
          error={isError}
          onChange={(e) => {
            const newDate = { ...date, month: e.target.value };
            handleChange(newDate);
          }}
          disabled={disabled}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex w-1/3 pr-2">
        <Select
          value={date.day}
          onBlur={noop}
          error={isError}
          onChange={(e) => {
            const newDate = { ...date, day: e.target.value };
            handleChange(newDate);
          }}
          disabled={disabled}
        >
          {days.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex w-1/3">
        <Select
          value={date.year}
          onBlur={noop}
          error={isError}
          onChange={(e) => {
            const newDate = { ...date, year: e.target.value };
            handleChange(newDate);
          }}
          disabled={disabled}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
