import * as colors from "components/Colors";
import { createTheme } from "@mui/material/styles";

export default (mode = "light") => {
  mode = "light"; // no more dark mode
  const m = (lightCol, darkCol) => {
    return mode === "light" ? lightCol : darkCol;
  };
  const col = (colText) => {
    return mode === "light"
      ? colors[`${colText}Light`]
      : colors[`${colText}Dark`];
  };
  const ocol = (colText) => {
    return mode !== "light"
      ? colors[`${colText}Light`]
      : colors[`${colText}Dark`];
  };
  return createTheme({
    colors,
    m,
    col,
    ocol,
    palette: {
      mode,
      background: {
        default: m("#F0EFF4", "#000000"),
      },
      primary: {
        main: m(colors.PrimaryLightColor, colors.PrimaryDarkColor),
        contrastText: m("#08d2d2", "#FFFFFF"),
      },
      secondary: {
        main: m("#F0EFF4", "#FFFFFF"),
        contrastText: m("#08d2d2", "#FFFFFF"),
      },
    },
    typography: { useNextVariants: true },
    props: {
      // Name of the component ⚛️
      MuiCheckbox: {
        // The default props to change
        color: "primary",
      },
      MuiSwitch: {
        color: "primary",
      },
    },
    overrides: {
      // Style sheet name ⚛️
      MuiInputBase: {
        // Name of the rule
        root: {
          // Some CSS
        },
      },
      MuiPickersDay: {
        daySelected: {
          color: "#08d2d2",
        },
      },
      MuiFormHelperText: {
        root: {
          display: "none",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderWidth: "0px",
          borderStyle: "solid",
          boxShadow:
            " 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        },
        notchedOutline: {
          borderColor: "none",
        },
        input: {
          padding: "9px 14px",
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: m("#FFFFFF", "#424242"),
          "&&$focused": {
            backgroundColor: m("#FFFFFF", "#424242"),
          },
        },
      },
      MuiButton: {
        outlined: {
          backgroundColor: m("#FFFFFF", "#424242"),
        },
      },
      MuiListSubheader: {
        sticky: {
          backgroundColor: m("#F0EFF4", "#000000"),
          zIndex: 10,
          marginBottom: 6,
        },
      },

      MuiAppBar: {
        colorSecondary: {
          backgroundColor: m("#F0EFF4", "#000000"),
        },
        colorPrimary: {
          backgroundColor: m("#F0EFF4", "#000000"),
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily:
              "Poppins, -apple-system, BlinkMacSystemFont, Segoe UI,Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
          },
        },
      },
    },
  });
};
