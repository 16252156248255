import { convertDateToAge, getLocaleDateFormat } from "utils/dateUtils";
import { replace, startCase, template } from "lodash";

import moment from "moment";
import split from "split-string";

const formatDate = (format, data) => {
  const res = moment(data).format(format);
  if (res === "Invalid date") {
    console.error("Invalid date", format, "for date:", data);
    return "";
  }
  return res;
};

const formatMoney = (amount, locale = "en-US") => {
  var formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(+amount);
};

const date = (formatIn) => (data) => {
  const format = formatIn || "MM/DD/YYYY";
  return formatDate(format, data);
};
const datetime = (formatIn) => (data) => {
  const format = formatIn || "MM/DD/YYYY hh:mm A";
  return formatDate(format, data);
};
const currentDate = (formatIn) => (data) => {
  const format = formatIn || "MM/DD/YYYY hh:mm A";
  const currentDate = moment().toDate();
  return formatDate(format, currentDate);
};
const age = (prop) => (data) => {
  return convertDateToAge(data);
};
const ago = (prop) => (data) => {
  return moment(data).fromNow(prop);
};
const upper = (prop) => (data) => {
  return data.toUpperCase();
};
const lower = (prop) => (data) => {
  return data.toLowerCase();
};
const cap = (prop) => (data) => {
  return startCase(data);
};
const mailto = (prop) => (data) => {
  return `<a href="mailto:${data}">${data}</a>`;
};
const tel = (prop) => (data) => {
  return `<a href="tel:${data}">${data}</a>`;
};
const link = (prop) => (data) => {
  return `<a href="${data}">${data}</a>`;
};
const currency = (locale) => (data) => {
  return formatMoney(data, locale);
};
let dateFormat = getLocaleDateFormat();
const time = (formatIn) => (data) => {
  const format = formatIn || "hh:mm A";
  return formatDate(format, data);
};
const int = (prop) => (data) => {
  try {
    const res = data + "";
    return +res;
  } catch (e) {
    return 0;
  }
};
const bool = (tVal, fVal) => (data) => {
  const asString = (data + "").toLowerCase();
  if (
    asString === "false" ||
    asString === "" ||
    +data === 0 ||
    data === false ||
    data === undefined ||
    data === null
  ) {
    return fVal || false;
  }
  return tVal || true;
};

const waitTime = (prop) => (data) => {
  if (data == "" || data == " ") {
    return "";
  } else {
    return data + " Mins";
  }
};
const dayOrDays = (prop) => (data) => {
  if (data == "" || data == " ") {
    return "";
  } else {
    if (Number(data) === 1) {
      return data + " Day";
    } else {
      return data + " Days";
    }
  }
};

const imports = {
  date,
  datetime,
  upper,
  lower,
  age,
  ago,
  cap,
  mailto,
  tel,
  link,
  currency,
  currentDate,
  time,
  int,
  bool,
  waitTime,
  dayOrDays,
};

export const templateWithFormatting = (str, data) => {
  const regex = /\$\{([\w\n[\].'\s-]+)\|?([\w|:'",\-/\s]*)\}/gm;
  let m;
  let newStr = str;

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    const originalPart = m[0];
    const varPart = m[1];
    const pipesPart = m[2];

    if (pipesPart) {
      const pipes = pipesPart.split("|");
      const pipeCalls = pipes.map((pipe) => {
        const signatureParts = split(pipe, {
          separator: ":",
          quotes: ["'"],
        });
        const func = signatureParts[0];
        const params = signatureParts[1];
        return `${func}(${params})(${varPart})`;
      });

      const singleCall = pipeCalls.reverse().reduce((acc, pipeCall) => {
        return replace(acc, varPart, pipeCall);
      }, varPart);

      newStr = replace(newStr, originalPart, `$\{${singleCall}}`);
    }
  }

  return template(newStr, { imports })({ ...data, dateFormat });
};
