import React from "react";
import useLocalization from "hooks/useLocalization";

const FixDeviceLink = ({ onLinkClick }) => {
  const [t] = useLocalization();
  return (
    <div className="">
      <button
        className="text-sm sm:text-base cursor-pointer underline underline-offset-2"
        style={{ color: "#145D61" }}
        onClick={() => onLinkClick(true)}
      >
        {t("techCheckValidateDevice.fixDevice")}
      </button>
    </div>
  );
};

export default FixDeviceLink;
