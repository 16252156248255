import React from "react";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { CheckCircleIcon } from "../ui/icons";
import { ThinkAndorButton } from "../ui/buttons";

const ConfirmationModal = ({ setParticipantIsConfirmed }) => (
  <div className="flex flex-col justify-between w-full bg-card p-4">
    <div className="flex flex-col items-center justify-center w-full m-auto">
      <CheckCircleIcon
        style={{
          fontSize: isMobileOnly ? "10rem" : "15.875rem",
          color: "#92d36e",
        }}
      />
    </div>
    <div className="w-full p-2 font-normal text-center font-text text-md text-on-card">
      The participants have been invited
    </div>
    <div className="flex flex-row justify-center w-full my-2">
      <ThinkAndorButton
        className="w-1/4"
        onClick={() => setParticipantIsConfirmed(false)}
      >
        Done
      </ThinkAndorButton>
    </div>
  </div>
);

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  setParticipantIsConfirmed: PropTypes.func,
};
