import "./css/videocall.css";
import "./css/bootstrap.min.css";

import { useStoreActions, useStoreState } from "easy-peasy";

import LanguageMenu from "../../components/ui/localizationMenu";
import Logo from "./images/andorlogo.png";
import React from "react";
import SettingsIcon from "./images/Settings-icon.png";
import { ShowIf } from "../../components/viewhelpers";
import Step1 from "./images/Step-One.png";
import Step10 from "./images/Step-Ten.png";
import Step10SPA from "./images/Step-Ten-SPA.png";
import Step11 from "./images/Step-Eleven.png";
import Step11SPA from "./images/Step-Eleven-SPA.png";
import Step12 from "./images/Step-Twelve.png";
import Step12SPA from "./images/Step-Twelve-SPA.png";
import Step1SPA from "./images/Step-One-SPA.png";
import Step2 from "./images/Step-Two.png";
import Step2SPA from "./images/Step-Two-SPA.png";
import Step3 from "./images/Step-Three.png";
import Step3SPA from "./images/Step-Three-SPA.png";
import Step4 from "./images/Step-Four.png";
import Step4SPA from "./images/Step-Four-SPA.png";
import Step5 from "./images/Step-Five.png";
import Step5SPA from "./images/Step-Five-SPA.png";
import Step6 from "./images/Step-Six.png";
import Step6SPA from "./images/Step-Six-SPA.png";
import Step7 from "./images/Step-Seven.png";
import Step7SPA from "./images/Step-Seven-SPA.png";
import Step8 from "./images/Step-Eight.png";
import Step8SPA from "./images/Step-Eight-SPA.png";
import Step9 from "./images/Step-Nine.png";
import Step9SPA from "./images/Step-Nine-SPA.png";
import { TechCheckNavBar } from "../../components/ui/general";
import useLocalization from "../../hooks/useLocalization";
import { useParams } from "react-router-dom";

const AndroidPage = () => {
  const [t, changeLanguage] = useLocalization();
  const { tenant } = useParams();

  const { changeCurrentLanguage } = useStoreActions(
    (actions) => actions.languageChangeState
  );
  const { defaultLanguage } = useStoreState(
    (state) => state.languageChangeState
  );
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();

  return (
    <div className="containerBox">
      <TechCheckNavBar
        links={
          <LanguageMenu
            className="flex cursor-pointer"
            currentLanguage={currentLanguage || defaultLanguage}
            changeCurrentLanguage={changeCurrentLanguage}
            tenantKey={tenant}
          />
        }
        className="mb-2"
      />
      <div className="videoHeader">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="clearfix"></div>
        <div className="pageHead">
          {t("faqPages.pageHead.title")} <br />{" "}
          {t("faqPages.pageHead.subtitle")}
          <br />
          <span>{t("faqPages.pageHead.description.forAndroidDevices")}</span>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="andorFeatureDataWrapper" id="androidDevices">
        <div className="row stepFlow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step1SPA} alt="step one" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step1} alt="step one" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 1
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep1.sub1"
                ),
              }}
            />
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep1.sub2"
              )}
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step2SPA} alt="step two" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step2} alt="step two" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 2
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep2"
                ),
              }}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step3SPA} alt="step three" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step3} alt="step three" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 3
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep3.sub1"
                ),
              }}
            />
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep3.sub2"
              )}
            </p>
          </div>
        </div>
        <div className="row stepFlow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step4SPA} alt="step four" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step4} alt="step four" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 4
            </div>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep4.sub1"
              )}
            </p>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep4.sub2"
              )}
            </p>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep4.sub3"
              )}
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step5SPA} alt="step five" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step5} alt="step five" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 5
            </div>
            <p className="flex justify-center">
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep5.sub1"
              )}
              <img
                src={SettingsIcon}
                alt="settings icon"
                style={{ width: "25px", height: "25px", marginLeft: "5px" }}
              />
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep5.sub2"
                ),
              }}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step6SPA} alt="step six" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step6} alt="step six" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 6
            </div>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep6.sub1"
              )}
            </p>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep6.sub2"
              )}
            </p>
          </div>
        </div>
        <div className="row stepFlow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step7SPA} alt="step seven" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step7} alt="step seven" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 7
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep7"
                ),
              }}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step8SPA} alt="step eight" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step8} alt="step eight" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 8
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep8.sub1"
                ),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep8.sub2"
                ),
              }}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step9SPA} alt="step nine" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step9} alt="step nine" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 9
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep9"
                ),
              }}
            />
          </div>
        </div>
        <div className="row stepFlow">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step10SPA} alt="step ten" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step10} alt="step ten" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 10
            </div>
            <p>
              {t("faqPages.android&iOSDevicesFaq.stepContent.androidStep10")}
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step11SPA} alt="step eleven" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step11} alt="step eleven" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 11
            </div>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep11.sub1"
              )}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep11.sub2"
                ),
              }}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={Step12SPA} alt="step twelve" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={Step12} alt="step twelve" />
            </ShowIf>
            <div className="pt-3 Step">
              {t("faqPages.android&iOSDevicesFaq.step")} 12
            </div>
            <p>
              {t(
                "faqPages.android&iOSDevicesFaq.stepContent.androidStep12.sub1"
              )}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "faqPages.android&iOSDevicesFaq.stepContent.androidStep12.sub2"
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AndroidPage;
