import React from "react";
import { ReactComponent as CloseIcon } from "images/close.svg";
import deviceOneImage from "images/tytocare-charching-dev1.png";
import deviceTwoImage from "images/tytocare-charching-dev2.png";
import useLocalization from "hooks/useLocalization";
import ArrowTip from "./ArrowTip";
import ChargingDevice from "./ChargingDevice";
import {
  TipsContainer,
  TipsContainerHeader,
  InfoText,
  Header,
} from "./styling";

const TytocareInfo = ({ setIsOpen }) => {
  const [t, changeLanguage] = useLocalization();
  const appleURL = "https://support.apple.com/en-us/HT213410";
  const androidURL =
    "https://www.androidauthority.com/how-to-find-your-wifi-password-on-android-1217177/";
  const windowsURL =
    "https://support.microsoft.com/en-us/windows/find-your-wi-fi-network-password-in-windows-2ec74b2e-d9ec-ade1-cc9b-bef1429cb678";
  return (
    <>
      <div className="flex justify-end w-8 cursor-pointer">
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <div className="px-4 mx-auto w-full max-w-2xl">
        <Header>{t("tytocareInfo.header")}</Header>
        <TipsContainer>
          <TipsContainerHeader>
            {t("tytocareInfo.tipHeader1")}
          </TipsContainerHeader>
          <ArrowTip>{t("tytocareInfo.tip1")}</ArrowTip>
          <ArrowTip>{t("tytocareInfo.tip2")}</ArrowTip>
          <ArrowTip>{t("tytocareInfo.tip3")}</ArrowTip>
          <ArrowTip>{t("tytocareInfo.tip4")}</ArrowTip>
        </TipsContainer>
        <TipsContainer>
          <TipsContainerHeader>
            {t("tytocareInfo.tipHeader2")}
          </TipsContainerHeader>
          <ArrowTip url={appleURL}>{t("tytocareInfo.tip5")}</ArrowTip>
          <ArrowTip url={androidURL}>{t("tytocareInfo.tip6")}</ArrowTip>
          <ArrowTip url={windowsURL}>{t("tytocareInfo.tip7")}</ArrowTip>
        </TipsContainer>
        <InfoText>{t("tytocareInfo.infoText1")}</InfoText>
        <InfoText>{t("tytocareInfo.infoText2")}</InfoText>
        <Header>{t("tytocareInfo.infoText3")}</Header>
        <div className="flex flex-wrap justify-center gap-2">
          <ChargingDevice
            header={t("tytocareInfo.device1.header")}
            instruction={t("tytocareInfo.device1.instruction")}
            charging={t("tytocareInfo.device1.charging")}
            charged={t("tytocareInfo.device1.charged")}
            image={deviceOneImage}
          />
          <ChargingDevice
            header={t("tytocareInfo.device2.header")}
            instruction={t("tytocareInfo.device2.instruction")}
            charging={t("tytocareInfo.device2.charging")}
            charged={t("tytocareInfo.device2.charged")}
            image={deviceTwoImage}
          />
        </div>
      </div>
    </>
  );
};

export default TytocareInfo;
