import React, { useEffect, useReducer, useState } from "react";
import { useStoreActions, useStoreState } from "whiteboard/AppState";
import HomeView from "../InviteParticipantForm/HomeView";
import ParticipantsForm from "./InviteForms/ParticipantsForm";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import useLocalization from "hooks/useLocalization";

const reducerFn = (state, payload) => {
  const key = payload.key || "participants";
  switch (payload.type) {
    case "ADD":
      let list = [...state[key], payload.value];
      return { ...state, [key]: list };
    case "DELETE":
      let newList = state[key].filter((e) => e.id !== payload.id);
      return { ...state, [key]: newList };
    default:
      return state;
  }
};

const InviteParticipantsForms = ({
  onCancel,
  onSendInvite,
  uriCode,
  tenantKey,
}) => {
  const { getRelationshipListWebRtc } = useStoreActions(
    (actions) => actions.techCheckState
  );
  const { relationshipListWebRTC } = useStoreState(
    (state) => state.techCheckState
  );
  const [t, changeLanguage] = useLocalization();

  const [renderView, setRenderView] = useState("homeView");

  const [data, dispatch] = useReducer(reducerFn, {
    participants: [],
  });

  const fetchData = async () => {
    const payload = { uriCode };
    await getRelationshipListWebRtc(payload);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={"flex flex-col w-auto h-auto px-3 sm:px-4 py-4 bg-card"}>
      <div className="p-3 text-base font-semibold">
        {t("techCheckValidateDevice.inviteText")}
      </div>
      {renderView === "homeView" && (
        <HomeView
          data={data}
          onCancel={onCancel}
          setRenderView={setRenderView}
          dispatch={dispatch}
          onSendInvite={onSendInvite}
        />
      )}
      {renderView === "inviteParticipantView" && (
        <ParticipantsForm
          dispatch={dispatch}
          setRenderView={setRenderView}
          relationList={relationshipListWebRTC}
          tenantKey={tenantKey}
        />
      )}
    </div>
  );
};

export default InviteParticipantsForms;

InviteParticipantsForms.propTypes = {
  onCancel: PropTypes.func,
  onSendInvite: PropTypes.func,
  uriCode: PropTypes.string,
  tenantKey: PropTypes.string,
};

InviteParticipantsForms.defaultTypes = {
  onCancel: noop,
  onSendInvite: noop,
  uriCode: "",
};
