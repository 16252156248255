import React from "react";

const OSBrowserContainer = ({ children }) => {
  return (
    <div className="flex items-center justify-center sm:justify-start gap-2 text-medium font-medium w-full">
      {children}
    </div>
  );
};

export default OSBrowserContainer;
