import React from "react";

export default (props) => {
  const { className, ...inputProps } = props;
  return (
    <label
      className={`block mb-2 text-sm font-bold bg-card ${className}`}
      {...inputProps}
    />
  );
};
