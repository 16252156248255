import styled from "styled-components";
import ModalPopper from "../../views/ModalPopper";
import { ReactComponent as UserAddIcon } from "../../images/user-add-line.svg";

export const SelfNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const ParticipantNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const StyledModalPopper = styled(ModalPopper)`
  .MuiDialog-paper {
    margin: ${(props) => (props.ismobileonly === "true" ? "0" : "32px")};
    position: relative;
    overflow-y: auto;
  }
  .MuiDialog-paperFullWidth {
    width: ${(props) =>
      props.ismobileonly === "true" ? "97%" : "calc(100% - 64px)"};
  }
`;

export const InviteUserAddIcon = styled(UserAddIcon)`
  background: white;
  fill: #2bc6ce;
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  padding: 0.5rem;
  flex-shrink: 0;
`;
