import React, { memo } from "react";
import styled from "styled-components";
import useLocalization from "hooks/useLocalization";

const StyledLink = styled.div`
  border-bottom: 3px solid #2bc6ce;
`;

const LanguageMenu = memo(
  ({ tenantKey, currentLanguage, changeCurrentLanguage }) => {
    const [t, changeLanguage] = useLocalization();
    const language = currentLanguage.toLowerCase();
    const handleChange = (lng) => {
      changeLanguage(lng);
      changeCurrentLanguage(lng);
    };
    switch (language) {
      case "en-us":
        return (
          <React.Fragment>
            {tenantKey === "fmolhs" && (
              <React.Fragment>
                <StyledLink>
                  <div
                    className="px-10 pt-6 text-sm font-bold cursor-pointer"
                    onClick={() => handleChange("en-us")}
                  >
                    English
                  </div>
                </StyledLink>
                <div
                  className="px-10 pt-6 text-sm cursor-pointer"
                  onClick={() => handleChange("en-es")}
                >
                  Español
                </div>
              </React.Fragment>
            )}

            {tenantKey !== "fmolhs" && (
              <React.Fragment>
                <StyledLink>
                  <div
                    className="pt-6 text-sm font-bold cursor-pointer xsm:px-3 xs:pr-6 xs:pl-10 sm:px-10 md:px-10 lg:px-10"
                    onClick={() => handleChange("en-us")}
                  >
                    English
                  </div>
                </StyledLink>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                  onClick={() => handleChange("en-es")}
                >
                  Español
                </div>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:px-4 sm:px-4 md:px-4 lg:px-4 xs:text"
                  onClick={() => handleChange("en-bis")}
                >
                  Haitian Creole
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case "en-es":
        return (
          <React.Fragment>
            {tenantKey === "fmolhs" && (
              <React.Fragment>
                <div
                  className="px-10 pt-6 text-sm cursor-pointer"
                  onClick={() => handleChange("en-us")}
                >
                  English
                </div>
                <StyledLink>
                  <div
                    className="px-10 pt-6 text-sm font-bold cursor-pointer"
                    onClick={() => handleChange("en-es")}
                  >
                    Español
                  </div>
                </StyledLink>
              </React.Fragment>
            )}
            {tenantKey !== "fmolhs" && (
              <React.Fragment>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:pr-6 xs:pl-10 sm:px-10 md:px-10 lg:px-10"
                  onClick={() => handleChange("en-us")}
                >
                  English
                </div>
                <StyledLink>
                  <div
                    className="pt-6 text-sm font-bold cursor-pointer xsm:px-3 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                    onClick={() => handleChange("en-es")}
                  >
                    Español
                  </div>
                </StyledLink>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                  onClick={() => handleChange("en-bis")}
                >
                  Haitian Creole
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case "en-bis":
        return (
          <React.Fragment>
            {tenantKey !== "fmolhs" && (
              <React.Fragment>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:pr-6 xs:pl-10 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                  onClick={() => handleChange("en-us")}
                >
                  English
                </div>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                  onClick={() => handleChange("en-es")}
                >
                  Español
                </div>
                <StyledLink>
                  <div
                    className="pt-6 text-sm font-bold cursor-pointer xsm:px-3 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                    onClick={() => handleChange("en-bis")}
                  >
                    Haitian Creole
                  </div>
                </StyledLink>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            {tenantKey === "fmolhs" && (
              <React.Fragment>
                <StyledLink>
                  <div
                    className="px-10 pt-6 text-sm font-bold cursor-pointer"
                    onClick={() => handleChange("en-us")}
                  >
                    English
                  </div>
                </StyledLink>
                <div
                  className="px-10 pt-6 text-sm cursor-pointer"
                  onClick={() => handleChange("en-es")}
                >
                  Español
                </div>
              </React.Fragment>
            )}
            {tenantKey !== "fmolhs" && (
              <React.Fragment>
                <StyledLink>
                  <div
                    className="pt-6 text-sm font-bold cursor-pointer xsm:px-3 xs:pr-6 xs:pl-10 sm:px-10 md:px-10 lg:px-10"
                    onClick={() => handleChange("en-us")}
                  >
                    English
                  </div>
                </StyledLink>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:px-6 sm:px-10 md:px-10 lg:px-10"
                  onClick={() => handleChange("en-es")}
                >
                  Español
                </div>
                <div
                  className="pt-6 text-sm cursor-pointer xsm:px-3 xs:px-4 sm:px-4 md:px-4 lg:px-4 xs:text-justify text"
                  onClick={() => handleChange("en-bis")}
                >
                  Haitian Creole
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        );
    }
  },
  (prevProps, nextProps) => {
    if (
      prevProps.currentLanguage === nextProps.currentLanguage &&
      prevProps.tenantKey === nextProps.tenantKey
    ) {
      return true;
    }
    return false;
  }
);

export default LanguageMenu;
