import LoadingIndicator from "@mui/material/CircularProgress";
import { MenuIcon } from "components/ui/icons";
import React from "react";
import classnames from "classnames";

export const BaseIconButton = ({ className, children, ...props }) => {
  return (
    <React.Fragment>
      <button
        className="flex items-center px-3 py-2 rounded border-on-card"
        {...props}
      >
        <MenuIcon />
      </button>
      {children}
    </React.Fragment>
  );
};

export const MenuButton = ({ children, ...props }) => {
  return <BaseIconButton>{children}</BaseIconButton>;
};

export const ButtonText = ({ children, ...props }) => {
  return (
    <button className="px-4 py-2 font-bold rounded text-brand" {...props}>
      {children}
    </button>
  );
};

export const Button = ({
  children,
  className,
  isSubmitting = false,
  ...props
}) => {
  return (
    <button
      className={`${
        props.disabled && "opacity-50"
      } bg-brand hover:bg-brand text-on-brand font-bold p-2 rounded items-center justify-center flex ${className}`}
      {...props}
    >
      {isSubmitting ? (
        <LoadingIndicator size={15} className="text-on-brand" />
      ) : (
        children
      )}
    </button>
  );
};
export const ThinkAndorButton = ({
  children,
  className,
  hover = true,
  customHeight = false,
  isShowLastMargin = true,
  fontWeight = "font-bold",
  ...props
}) => {
  return (
    <button
      className={classnames(
        `focus:outline-none bg-brand disabled:opacity-50 text-on-brand ${fontWeight} py-2 px-3 rounded ${className}`,
        {
          "hover:bg-brand": hover,
          "h-9": customHeight,
          "last:mr-0": isShowLastMargin,
        }
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonWithLoader = ({
  children,
  className,
  isSubmitting = false,
  fontWeight = "font-bold",
  padding = "p-2",
  ...props
}) => {
  return (
    <button
      className={`${
        props.disabled && "opacity-50"
      } bg-brand hover:bg-brand text-on-brand ${fontWeight} ${padding} rounded items-center justify-center flex ${className}`}
      {...props}
    >
      {isSubmitting ? (
        <LoadingIndicator size={15} className="text-on-brand" />
      ) : (
        children
      )}
    </button>
  );
};

export const LinkButton = ({ children, className, ...props }) => {
  return (
    <a
      className={`${
        props.disabled && "opacity-50"
      } bg-brand hover:bg-brand text-on-brand font-bold py-2 px-4 rounded ${className}`}
      {...props}
    >
      {children}
    </a>
  );
};

export const ButtonWithLoaderOnBrand = ({
  children,
  className,
  isSubmitting = false,
  fontWeight = "font-bold",
  ...props
}) => (
  <button
    className={`${
      props.disabled && "opacity-50"
    } bg-brand hover:hc-bg-brand text-on-brand p-2 ${fontWeight} rounded items-center justify-center flex ${className}`}
    {...props}
  >
    {isSubmitting ? (
      <LoadingIndicator size={15} className="text-on-brand" />
    ) : (
      children
    )}
  </button>
);
