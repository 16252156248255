import { BaseScreen, CenteredScreen, ContentArea } from "components/ui/general";
import { Group, Input, Label } from "components/ui/forms";

import { Button } from "components/ui/buttons";
import React from "react";

export const LoginForm = ({ lastName, date }) => {
  return (
    <BaseScreen>
      <ContentArea className="px-2 pb-2">
        <CenteredScreen>
          <Group>
            <Label>Patient Last Name</Label>
            <Input value={lastName} placeholder="Last Name" />
          </Group>
          <Group>
            <Label>Date of Birth</Label>
            <Input value={date} placeholder="mm/dd/yyyy" />
          </Group>
          <Group>
            <Button className="w-full">LOGIN</Button>
          </Group>
        </CenteredScreen>
      </ContentArea>
    </BaseScreen>
  );
};

export default LoginForm;
