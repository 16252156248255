import { action, thunk } from "easy-peasy";
import {
  getFormData,
  getPublicImage,
  makeAPIGetCall,
  submitFormData,
  uploadFormFile,
} from "services/PlatformService";

import assign from "lodash/assign";

export const FormState = {
  isReady: false,
  isValid: true,
  form: { previousPageId: 0, formDefinition: { sections: [] } },
  getFormData: thunk(async (actions, payload, helpers) => {
    if (!payload.uriCode || !payload.pageId) {
      return;
    }
    const state = helpers.getState();
    if (state.isReady) actions.setData({ isReady: false });
    const { formDefinition, formId, isCompleted, pageId, uriCode, ...others } =
      await getFormData({ uriCode: payload.uriCode, pageId: payload.pageId });
    if (!uriCode)
      return actions.setData({ isValid: false, message: others.Message });
    actions.setData({
      isReady: true,
      form: {
        formDefinition,
        formId,
        isCompleted,
        pageId,
        uriCode,
        previousPageId: state.form.previousPageId,
      },
    });
  }),
  handleFileUpload: thunk(async (actions, payload, helpers) => {
    const response = await uploadFormFile(payload);
    return response;
  }),
  submitFormData: thunk(async (actions, payload, helpers) => {
    const response = await submitFormData(payload);
    actions.setPreviousPageId(response.previousPageId);
    return response;
  }),
  makeAPIGetCall: thunk(async (actions, { url, params }, helpers) => {
    const response = await makeAPIGetCall(url, params);
    return response;
  }),
  getPublicImage: thunk(async (actions, { uriCode, blobUrl }, helpers) => {
    const response = await getPublicImage(uriCode, blobUrl);
    return response;
  }),
  //Utility Functions
  setData: action((state, data) => {
    assign(state, data);
  }),
  setPreviousPageId: action((state, previousPageId) => {
    state.form.previousPageId = previousPageId;
  }),
};

export default FormState;
