import { action, thunk } from "easy-peasy";
import {
  completeQuestionnaire,
  getPersonTypeProperties,
  getPersonTypes,
  getQuestionnaire,
  validateEmployee,
} from "services/PlatformService";

import assign from "lodash/assign";
import { convertDateToString } from "utils/dateUtils";

export const TriageState = {
  form: {},
  questionnaire: null,
  isReady: false,
  personTypes: [],
  personTypeProperties: [],
  languageCode: "",
  performInit: thunk(async (actions, { tenant, code, uriCode }, helpers) => {
    const tenantKey = tenant;
    actions.setData({ tenant, code, uriCode });
    const personTypes = await getPersonTypes({
      tenantKey,
      questionnaireType: code,
    });
    actions.setData({ personTypes });
    if (personTypes.length === 1) {
      actions.setSelectedPersonType(personTypes[0].personType);
    }
    actions.setReady();
  }),
  setSelectedPersonType: action((state, selectedPersonType) => {
    state.personType = selectedPersonType;
  }),

  getQuestionnaire: thunk(
    async (actions, { tenantKey, personType }, helpers) => {
      const { code, uriCode } = helpers.getStoreState().triage;
      actions.setSelectedPersonType(personType);
      const questionnaireType = code;
      const questionnaireResult = await getQuestionnaire({
        tenantKey,
        questionnaireType,
        uriCode,
        personType,
      });
      let { questionnaire, person, languageCode } = questionnaireResult;
      if (person && person.dob) {
        person = { ...person, dob: convertDateToString(person.dob) };
      }
      actions.setData({ languageCode, questionnaire, form: { ...person } });
      const personTypeResponse = await getPersonTypeProperties({
        tenantKey,
        personType,
      });
      const { personTypeProperties } = personTypeResponse;
      actions.setData({
        personTypeProperties,
      });
      return { personTypeProperties, questionnaire, person };
    }
  ),

  onValidateEmployee: thunk(
    async (actions, { tenantKey, externalEmployeeId, lastName }, helpers) => {
      const response = await validateEmployee({
        tenantKey,
        lastName,
        externalEmployeeId,
      });
      return response.isValid;
    }
  ),

  saveFormData: action((state, data) => {
    state.form = data;
  }),
  saveAnswersToQuestions: action((state, data) => {
    state.questionnaire.questions.map((question) => {
      question.value = data[question.questionId];
      return question;
    });
  }),
  submitQuestionnaire: thunk(async (actions, obj, helpers) => {
    const state = helpers.getStoreState();
    const triage = state.triage;
    const tenantKey = triage.tenant;
    const uriCode = triage.uriCode;
    const questionnaire = triage.questionnaire;
    const personType = triage.personType;
    const payload = {
      tenantKey,
      uriCode,
      questionnaireType: questionnaire.questionnaireType,
      questionnaireId: questionnaire.questionnaireId,
      person: { ...triage.form, personType },
      personQuestions: questionnaire.questions.map((question) => {
        return {
          questionId: question.questionId,
          responses: [{ response: question.value }],
        };
      }),
    };
    const result = await completeQuestionnaire(payload);
    actions.setData({ ...result, questionnaireComplete: true });
    return result;
  }),
  setData: action((state, data) => {
    assign(state, data);
  }),
  setReady: action((state) => {
    state.isReady = true;
  }),
};

export default TriageState;
