import { action, thunk } from "easy-peasy";
import { getBranding, getTenantViewConfig } from "services/PlatformService";

import assign from "lodash/assign";

export const TenantBrandingState = {
  getTenantBranding: thunk(async (actions, payload, helpers) => {
    const response = await getBranding(payload);
    actions.setData({ tenantView: response.view });
    return response;
  }),
  getTenantBrandingViewConfig: thunk(async (actions, payload, helpers) => {
    const response = await getTenantViewConfig(payload);
    if (payload.name === "GLOBAL_TECH_CHECK_VIEW_V2") {
      actions.setData({ ...response.view });
    } else {
      actions.setData({ BrandingViewConfig: response.view });
    }
    return response;
  }),
  setData: action((state, data) => {
    assign(state, data);
  }),
};

export default TenantBrandingState;
