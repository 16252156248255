import { useNavigate } from "react-router-dom";

export const useLink = (defaultState = false) => {
  const navigate = useNavigate();

  const link = (url) => () => {
    navigate(url);
  };

  return [link, navigate];
};

export default useLink;
