import { AlarmOffIcon, LinkIcon } from "components/ui/icons";

import { BaseScreen } from "components/homepage/General";
import React from "react";
import { useParams } from "react-router-dom";

const urls = {
  oim: "http://www.oimonline.com/",
  northwell: "https://www.northwell.edu/",
};

export const GenericPage = () => {
  const { tenant } = useParams();
  if (!!tenant && urls[tenant.toLowerCase()]) {
    window.location.replace(urls[tenant.toLowerCase()]);
    return <React.Fragment />;
  } else {
    return (
      <BaseScreen>
        <div style={{ width: 200, height: 190 }} className="relative mx-auto">
          <LinkIcon
            style={{ fontSize: 200 }}
            className="absolute left-0 text-on-card"
          />
          <AlarmOffIcon
            style={{ fontSize: 100 }}
            className="absolute bottom-0 right-0 text-danger"
          />
        </div>
        <h1 className="py-4 text-2xl font-bold text-center text-on-card">
          Incorrect link or link has expired.
        </h1>
        <p className="px-1 pt-2 text-sm text-center text-on-card">
          Please contact Andor at (888) 355-5046 between 8 AM and 5 PM
          Monday-Friday if you would like help with this link.
        </p>
      </BaseScreen>
    );
  }
};

export default GenericPage;
