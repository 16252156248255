import React from "react";
import ParticipantForm from "./ParticipantForm";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isMobileOnly } from "react-device-detect";

const ParticipantsForm = ({
  dispatch,
  setRenderView,
  relationList,
  tenantKey,
}) => {
  return (
    <div
      className={classnames("flex flex-col rounded-md shadow-md bg-card", {
        "m-3": !isMobileOnly,
      })}
    >
      <ParticipantForm
        relationList={relationList}
        tenantKey={tenantKey}
        setRenderView={setRenderView}
        dispatch={dispatch}
      />
    </div>
  );
};

export default ParticipantsForm;

ParticipantsForm.propTypes = {
  dispatch: PropTypes.func,
  setRenderView: PropTypes.func,
  relationList: PropTypes.array,
  tenantKey: PropTypes.string,
};

ParticipantsForm.defaultProps = {
  relationList: [],
};
