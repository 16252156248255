import React, { useState } from "react";
import PropTypes from "prop-types";
import ExpandOutlinedIcon from "../../../../images/fag-arrowdown.svg";
import classnames from "classnames";

const DisplayCollapse = ({ value }) => {
  const [currentId, setCurrentId] = useState([0]);

  const ExpandLessIcon = () => {
    return (
      <img
        src={ExpandOutlinedIcon}
        className="transform origin-center rotate-180"
        alt="click for less"
      />
    );
  };
  const ExpandMoreIcon = () => {
    return <img src={ExpandOutlinedIcon} alt="click for more" />;
  };
  return (
    <>
      {value.map((v) => (
        <div
          className="px-2 py-3 border-b-2 text-left border-[#707070]"
          key={v.id}
        >
          <div
            className="flex justify-between gap-4 cursor-pointer"
            onClick={() =>
              currentId.includes(parseInt(v.id))
                ? setCurrentId(
                    currentId.filter((int) => int !== parseInt(v.id))
                  )
                : setCurrentId([...currentId, parseInt(v.id)])
            }
          >
            <div className="text-lg font-bold sm:text-xl">{v.name}</div>
            {!currentId.includes(parseInt(v.id)) ? (
              <ExpandMoreIcon />
            ) : (
              <ExpandLessIcon />
            )}
          </div>
          <div
            className={classnames("text-base p-3", {
              hidden: !currentId.includes(parseInt(v.id)),
            })}
            dangerouslySetInnerHTML={{ __html: v.content }}
          />
        </div>
      ))}
    </>
  );
};

export default DisplayCollapse;

DisplayCollapse.propTypes = {
  value: PropTypes.array,
};

DisplayCollapse.defaultProps = {
  value: [],
};
