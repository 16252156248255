import React from "react";
import AppleSafariIcon from "../../../images/appleSafariNew.png";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import GoogleChromeIcon from "../../../images/chromeNew.png";
import IphoneSettingsIcon from "../../../images/settings.png";
import useLocalization from "../../../hooks/useLocalization";
import { useParams } from "react-router-dom";
import SafariBrowserIcon from "../../../images/Safari.png";
import chromeBrowserIcon from "../../../images/Chrome.png";
import edgeBrowserIcon from "../../../images/Edge.png";

export const IncompatibleDeviceFaq = ({ setIsOpen }) => {
  const { tenant } = useParams();
  const [t] = useLocalization();

  return (
    <React.Fragment>
      <div className="flex justify-end w-8 cursor-pointer">
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <div className="flex justify-center p-4 text-lg font-bold">
        {t("faqIncompatible.header")}
      </div>
      <div className="flex flex-col p-4">
        <div className="flex flex-row items-center justify-start pb-2">
          <img
            src={IphoneSettingsIcon}
            alt="iphone settings"
            className="flex w-10 h-10"
          ></img>
          <div className="pl-4 font-bold underline text-md">
            {t("faqIncompatible.iPhone")}
          </div>
        </div>
        <div className="text-md">
          {`${t("faqIncompatible.iPhoneMessage")}. ${t(
            "faqIncompatible.iOSupdate"
          )}`}
        </div>
      </div>
      <div className="flex flex-col p-4">
        <div className="flex flex-row items-center justify-start pb-2">
          <img
            src={AppleSafariIcon}
            alt="Apple Safari Icon"
            className="flex w-10 h-10"
          />
          <div className="pl-4 font-bold underline text-md">
            {t("faqIncompatible.safari")}
          </div>
        </div>
        <div className="font-medium text-md">
          {t("faqIncompatible.safariMessage")}
        </div>
        <ol className="px-4 pb-4" style={{ listStyleType: "decimal" }}>
          <li>{t("faqIncompatible.tip1")}</li>
          <li>{t("faqIncompatible.tip2")}</li>
          <li>{t("faqIncompatible.tip3")}</li>
          <li>{t("faqIncompatible.tip4")}</li>
        </ol>
      </div>
      {tenant === "conviva" && (
        <>
          <div className="flex flex-col px-4 pb-1">
            <div className="flex flex-row items-center justify-start">
              <img
                src={GoogleChromeIcon}
                alt="Google Chrome Icon"
                className="flex w-10 h-10"
              />
              <div className="pl-4 font-bold underline text-md">
                {t("faqIncompatible.chromeMessage")}
              </div>
            </div>
          </div>

          <div className="flex flex-col px-4 py-1 mb-16">
            {`${t("faqIncompatible.switchConvivaDevices")} ${t(
              "faqIncompatible.convivaCallCenter"
            )} ${t("faqIncompatible.thankYou")}`}
          </div>
        </>
      )}
      {tenant !== "conviva" && (
        <>
          <div className="flex flex-col px-4 pb-1">
            <div className="flex flex-row items-center justify-start">
              <img
                src={GoogleChromeIcon}
                alt="Google Chrome Icon"
                className="flex w-10 h-10"
              />
              <div className="pl-4 font-bold underline text-md">
                {t("faqIncompatible.chromeMessage")}
              </div>
            </div>
          </div>

          <div className="flex flex-col px-4 py-1 mb-16">
            {`${t("faqIncompatible.switchDevices")} ${t(
              "faqIncompatible.callCenter"
            )} ${t("faqIncompatible.thankYou")}`}
          </div>
        </>
      )}

      <>
        <div className="flex flex-col px-4 pb-1">
          <div className="flex flex-row items-center justify-start">
            <img
              src={GoogleChromeIcon}
              alt="Google Chrome Icon"
              className="flex w-10 h-10"
            />
            <div className="pl-4 font-bold underline text-md">
              {t("faqIncompatible.windowsOS")}
            </div>
          </div>
        </div>
        <div className="flex flex-col px-4 py-1 mb-16">
          <div className="text-md">{t("faqIncompatible.windowsOSMessage")}</div>
          {`${t("faqIncompatible.switchDevices")} ${t(
            "faqIncompatible.callCenter"
          )} ${t("faqIncompatible.thankYou")}`}
        </div>
      </>
      <>
        <div className="flex flex-col px-4 pb-1">
          <div className="flex flex-row items-center justify-start">
            <img
              src={AppleSafariIcon}
              alt="Google Chrome Icon"
              className="flex w-10 h-10"
            />
            <div className="pl-4 font-bold underline text-md">
              {t("faqIncompatible.macOS")}
            </div>
          </div>
        </div>
        <div className="flex flex-col px-4 py-1 mb-16">
          <div className="text-md">{t("faqIncompatible.macOSMessage")}</div>
          {`${t("faqIncompatible.switchDevices")} ${t(
            "faqIncompatible.callCenter"
          )} ${t("faqIncompatible.thankYou")}`}
        </div>
      </>
      <div className="flex justify-center clear-both max-w-md mb-3 px-4 py-2 ">
        <img
          onClick={() =>
            window.open(
              "https://www.google.com/chrome/?brand=YTUH&geo=US&gclid=EAIaIQobChMInvDugJSY8wIVjJ-zCh0pOQSuEAAYASAAEgJu8fD_BwE&gclsrc=aw.ds"
            )
          }
          className="h-1/3 w-1/3  cursor-pointer "
          src={chromeBrowserIcon}
          alt=""
        />
        <img
          onClick={() =>
            window.open(
              "https://www.microsoft.com/en-us/edge?brand=M022&OCID=AID2200279_SEM_EAIaIQobChMI4L6sjZSY8wIVUAaICR375AjkEAAYASAAEgINE_D_BwE:G:s&ef_id=EAIaIQobChMI4L6sjZSY8wIVUAaICR375AjkEAAYASAAEgINE_D_BwE:G:s&gclid=EAIaIQobChMI4L6sjZSY8wIVUAaICR375AjkEAAYASAAEgINE_D_BwE"
            )
          }
          className="h-1/3 w-1/3  cursor-pointer"
          src={edgeBrowserIcon}
          alt=""
        />
        <img
          onClick={() =>
            window.open("https://support.apple.com/downloads/safari")
          }
          className="h-1/3 w-1/3  cursor-pointer"
          src={SafariBrowserIcon}
          alt=""
        />
      </div>
    </React.Fragment>
  );
};
