import axios from "axios";
import get from "lodash/get";

const performLoginWithAuthService = () => {
  const authorizationUrl = `${window.AUTH_CONNECT_URL}/connect/authorize`;
  const clientid = window.AUTH_CLIENT_ID;
  const redirecturi = window.location.href;
  const responsetype = "id_token token";
  const scope = "openid uirestapi";
  const nonce = "N" + Math.random() + "" + Date.now();
  const state = Date.now() + "" + Math.random();

  const url =
    authorizationUrl +
    "?" +
    "response_type=" +
    encodeURI(responsetype) +
    "&" +
    "client_id=" +
    encodeURI(clientid) +
    "&" +
    "scope=" +
    encodeURI(scope) +
    "&" +
    "nonce=" +
    encodeURI(nonce) +
    "&" +
    "state=" +
    encodeURI(state) +
    "redirect_uri=" +
    encodeURI(redirecturi);
  console.log("LOGIN REDIRECT", url);
  window.location.href = url;
};

const handleStatus = (e) => {
  const response = e.response ? e.response : e;
  const status = response ? response.status : "unknown";
  switch (status) {
    case 400:
    case 404:
    case 429:
    case 500:
    case "unknown":
      return console.error(e);
    case 401:
    case 403:
      // not authorized
      performLoginWithAuthService();
      break;
    default:
      break;
  }
  return e;
};

const logError = (e) => {
  handleStatus(e);
  return get(e, "response.data", {});
};

const requestService = (config) => {
  const { userKey, isOldAPI, url, ...otherConfig } = config;
  const baseAPIURL = isOldAPI ? window.OLD_API_URL : window.API_URL;
  const domain = url.indexOf("http") >= 0 ? "" : baseAPIURL || "";
  const headers = window.userKey && {
    authorization: `Bearer ${window.userKey}`,
  };
  return axios
    .request({
      headers,
      url: `${domain}${url}`,
      ...otherConfig,
    })
    .then((res) => {
      if (res.status !== 200) {
        console.error("PLATFORM SERVICE ERROR", res);
      }
      return res;
    })
    .then((res) => res.data)
    .catch(logError);
};

const getService = (url, params, userKey) => {
  return requestService({
    method: "get",
    url,
    params,
    userKey,
  });
};

export const oldGetService = (url, params) => {
  return requestService({
    method: "get",
    url,
    params,
    isOldAPI: true,
  });
};

const postService = (url, data, userKey, other) => {
  return requestService({
    method: "post",
    url,
    data,
    userKey,
    ...other,
  });
};

export const getFile = (filePath) => {
  return axios
    .get(filePath)
    .then((res) => {
      return res.data;
    })
    .catch(logError);
};

export const getFeatures = () => {
  return getService(`/api/Feature/GetFeatures`, {}).then((res) => res);
};

export const getRedirectURL = (uriCode) => {
  return getService(`/api/andornow/q/${uriCode}`, {}).then((res) => res);
};

export const getQuestionnaire = ({
  tenantKey,
  questionnaireType,
  uriCode,
  personType,
}) => {
  const payload = uriCode
    ? {
        uriCode,
        personType,
      }
    : {
        tenantKey,
        questionnaireType,
        personType,
      };
  return getService(`/api/andornow/questionnaire`, payload).then((res) => res);
};

export const getPersonTypes = ({ tenantKey, questionnaireType }) => {
  return getService(
    `/api/andornow/person-types?tenantKey=${tenantKey}&questionnaireType=${questionnaireType}`
  );
};

export const validateEmployee = (payload) => {
  return getService(`/api/andornow/validate-employee`, payload);
};

export const getBranding = (payload) => {
  return getService(`${window.API_URL}/api/taxonomy/branding`, payload);
};

export const getTenantViewConfig = (payload) => {
  return getService(`${window.API_URL}/api/andornow/view-config`, payload);
};

export const updateSupportedDevice = (payload) => {
  return postService(`/api/virtual-health/meeting/techcheck`, payload);
};

export const getScheduledAppointments = ({ uriCode }) => {
  return getService(`/api/virtual-health/meeting/techcheck?uriCode=${uriCode}`);
};

export const getPersonTypeProperties = ({ tenantKey, personType }) => {
  return getService(
    `/api/andornow/person-types-properties?tenantKey=${tenantKey}&personType=${personType}`
  );
};

export const completeQuestionnaire = (data) => {
  return postService(`/api/andornow/complete-questionnaire`, data).then(
    (res) => res
  );
};

export const getSearchSuggestions = ({ term }) => {
  const data = { searchTerm: term, searchLimit: 10 };
  return postService(`/api/andornow/problem-list`, data).then((res) => res);
};

export const getSearchResults = ({ search, language, tenantKey }) => {
  const data = { search, language, tenantKey };
  return postService(`/api/education/search`, data).then((res) => res);
};

export const getFormData = (payload) => {
  return getService(`${window.THINKANDOR_API_URL}/api/form`, payload);
};

export const submitFormData = (payload) => {
  return postService(`${window.THINKANDOR_API_URL}/api/form`, payload);
};

export const uploadFormFile = ({ capturedImage, uriCode, controlId }) => {
  const formData = new FormData();
  formData.append("capturedImage", capturedImage);
  formData.append("controlId", controlId);
  formData.append("uriCode", uriCode);
  return axios
    .post(`${window.THINKANDOR_API_URL}/api/form/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((e) => logError(e));
};

export const makeAPIGetCall = (url, payload) => {
  return getService(`${window.THINKANDOR_API_URL}${url}`, payload);
};

export const getPublicImage = (uriCode, blobUrl) => {
  return postService(`${window.THINKANDOR_API_URL}/api/form/blobsas`, {
    uriCode,
    blobUrl,
  });
};

export const getRelationshipTypes = (uriCode) => {
  return getService(`/api/patient/relationship-types/${uriCode}`);
};

export const inviteParticipant = (payload) => {
  return postService(
    `/api/virtual-health/meeting/public/invite-to-session`,
    payload
  );
};
