import "./css/videocall.css";
import "./css/bootstrap.min.css";

import { useStoreActions, useStoreState } from "easy-peasy";

import AppleSettings from "./images/appleSettinggs.png";
import L2Step1 from "./images/level2-step-one.png";
import L2Step1SPA from "./images/level2-step-one-spa.png";
import L2Step2 from "./images/level2-step-two.png";
import L2Step2SPA from "./images/level2-step-two-spa.png";
import L2Step3 from "./images/level2-step-three.png";
import L2Step3SPA from "./images/level2-step-three-spa.png";
import L2Step4 from "./images/level2-step-four.png";
import L2Step4SPA from "./images/level2-step-four-spa.png";
import L2Step5 from "./images/level2-step-five.png";
import L2Step5SPA from "./images/level2-step-five-spa.png";
import L2Step6 from "./images/level2-step-six.png";
import L2Step6SPA from "./images/level2-step-six-spa.png";
import L2Step7 from "./images/level2-step-seven.png";
import L2Step7SPA from "./images/level2-step-seven-spa.png";
import L2Step8 from "./images/level2-step-eight.png";
import L2Step8SPA from "./images/level2-step-eight-spa.png";
import LanguageMenu from "../../components/ui/localizationMenu";
import Logo from "./images/andorlogo.png";
import React from "react";
import { ShowIf } from "../../components/viewhelpers";
import { TechCheckNavBar } from "../../components/ui/general";
import useLocalization from "../../hooks/useLocalization";
import { useParams } from "react-router-dom";

const IOSPage = () => {
  const [t, changeLanguage] = useLocalization();

  const { tenant } = useParams();

  const { changeCurrentLanguage } = useStoreActions(
    (actions) => actions.languageChangeState
  );
  const { defaultLanguage } = useStoreState(
    (state) => state.languageChangeState
  );
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();

  return (
    <div className="containerBox">
      <TechCheckNavBar
        links={
          <LanguageMenu
            className="cursor-pointer"
            currentLanguage={currentLanguage || defaultLanguage}
            changeCurrentLanguage={changeCurrentLanguage}
            tenantKey={tenant}
          />
        }
        className="mb-2"
      />
      <div className="videoHeader">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="clearfix"></div>
        <div className="pageHead">
          {t("faqPages.pageHead.title")} <br />{" "}
          {t("faqPages.pageHead.subtitle")}
          <br />
          <span>{t("faqPages.pageHead.description.foriOSDevices")}</span>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="andorFeatureDataWrapper" id="appleDevices">
        <div className="row stepappleFlow">
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7 ">
                  <img src={L2Step1SPA} alt="step one" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step1} alt="step one" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 1
                </div>
                <p>
                  {t("faqPages.android&iOSDevicesFaq.stepContent.iOSStep1")}
                </p>
              </div>
            </div>
          </div>
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step2SPA} alt="step two" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step2} alt="step two" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 2
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep2"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7 ">
                  <img src={L2Step3SPA} alt="step three" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step3} alt="step three" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 3
                </div>
                <p>
                  {t(
                    "faqPages.android&iOSDevicesFaq.stepContent.iOSStep3.sub1"
                  )}
                </p>
                <p>
                  {t(
                    "faqPages.android&iOSDevicesFaq.stepContent.iOSStep3.sub2"
                  )}
                </p>
                <p>
                  {t(
                    "faqPages.android&iOSDevicesFaq.stepContent.iOSStep3.sub3"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step4SPA} alt="step four" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step4} alt="step four" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 4
                </div>
                <p className="align-items-center">
                  {t("faqPages.android&iOSDevicesFaq.stepContent.iOSStep4")}
                  <img
                    src={AppleSettings}
                    alt="settings icon"
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "inline-block",
                      margin: "0",
                      marginLeft: "5px",
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step5SPA} alt="step five" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step5} alt="step five" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 5
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep5"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step6SPA} alt="step six" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step6} alt="step six" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 6
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep6.sub1"
                    ),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep6.sub2"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step7SPA} alt="step seven" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step7} alt="step seven" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 7
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep7"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pb-5 col-sm-12 col-md-12 col-lg-6">
            <div className="p-0 row">
              <ShowIf test={currentLanguage === "en-es"}>
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step8SPA} alt="step eight" />
                </div>
              </ShowIf>
              <ShowIf
                test={
                  currentLanguage === "en-us" || currentLanguage === "en-bis"
                }
              >
                <div className="p-0 col-sm-12 col-md-7">
                  <img src={L2Step8} alt="step eight" />
                </div>
              </ShowIf>
              <div className="pl-0 col-sm-12 col-md-4">
                <div className="StepApple">
                  {t("faqPages.android&iOSDevicesFaq.step")} 8
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep8.sub1"
                    ),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "faqPages.android&iOSDevicesFaq.stepContent.iOSStep8.sub2"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IOSPage;
