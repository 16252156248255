import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { ErrorOutline } from "components/ui/icons";

const InfoBanner = ({ currentLanguage, pageHeader, message }) => {
  const [infoMessage, setInfoText] = useState("");

  const getInfoText = useCallback(
    (language) => {
      const requestedHeader = pageHeader.find(
        (header) =>
          header?.languageCode?.toLowerCase() === language?.toLowerCase()
      );
      return requestedHeader?.message;
    },
    [currentLanguage, pageHeader]
  );

  useEffect(() => {
    if (!message) {
      const infoMessage = getInfoText(currentLanguage);
      setInfoText(infoMessage);
    } else {
      setInfoText(message);
    }
  }, [currentLanguage, message]);

  return (
    <div className="max-w-3xl mx-3 my-2 p-3 flex justify-center items-center gap-4 self-center leading-4 text-sm font-medium sm:leading-6 sm:text-xl bg-[#FBF4E0] border-2 border-solid border-[#966600] rounded-[5px] text-[#6B6D6E]">
      <ErrorOutline className="w-8 h-8 font-light text-[#966600]" />
      <div>{infoMessage}</div>
    </div>
  );
};

export default InfoBanner;

InfoBanner.propTypes = {
  currentLanguage: PropTypes.string,
  pageHeader: PropTypes.array,
  message: PropTypes.string,
};

InfoBanner.defaultProps = {
  pageHeader: [],
  message: "",
  currentLanguage: "",
};
