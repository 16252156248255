import styled from "styled-components";
import wifiarrow from "images/arrow-wifi.svg";
import chargingDeviceImage from "images/tyto_thank_you_image.png";

export const TipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 1.5rem;
`;
export const ArrowTipText = styled.p`
  padding-left: 0.5rem;
  margin-bottom: 0 !important;
  font-size: 0.875rem;
`;
export const ArrowTipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding-bottom: 0.5rem;
`;
export const TipsContainerHeader = styled.p``;

export const InfoText = styled.p`
  width: 100%;
`;
export const Header = styled.p`
  text-align: center;
  margin-inline: auto;
  padding-block: 0.5rem;
  font-size: 1.125rem;
  font-weight: 700;
`;
export const ArrowTipImage = styled.div.attrs(() => ({
  alt: "tip",
}))`
  background-image: url(${wifiarrow});
  background-repeat: no-repeat;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
`;

export const ArrowTipLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noreferrer",
}))`
  padding-left: 0.5rem;
  font-size: 0.875rem;
  text-decoration: underline;
`;
