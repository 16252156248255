import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import React from "react";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";

const ModalPopper = ({ width = "sm", ...props }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up(width));
  return (
    <Dialog
      TransitionComponent={!matches ? TransitionUp : Fade}
      maxWidth={width}
      fullScreen={!matches}
      fullWidth={true}
      {...props}
    />
  );
};

export const TransitionUp = React.forwardRef(function Transition(
  { children, ...props },
  ref
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {children}
    </Slide>
  );
});

export default ModalPopper;
