import { action, thunk } from "easy-peasy";
import {
  getSearchResults,
  getSearchSuggestions,
} from "services/PlatformService";

import assign from "lodash/assign";

export const HealthSearchState = {
  isReady: false,
  results: "",
  searchResults: "",
  searchSuggestions: {},
  performSearch: thunk(
    async (actions, { search, lang, tenantKey }, helpers) => {
      const language = lang ?? "";
      if (search === "" || search === " ") {
        search = "covid";
      }
      const searchResults = await getSearchResults({
        search,
        language,
        tenantKey,
      });
      actions.setData({ searchResults });
      actions.setReady();
    }
  ),
  getSuggestions: thunk(async (actions, { term }, helpers) => {
    const searchSuggestions = await getSearchSuggestions({ term: term });
    actions.setData({ searchSuggestions });
  }),
  setData: action((state, data) => {
    assign(state, data);
  }),
  setReady: action((state) => {
    state.isReady = true;
  }),
};

export default HealthSearchState;
