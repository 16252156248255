import React from "react";
import { isNativeDarkMode } from "components/ThemeType";
import { useParams } from "react-router-dom";
import { useStoreState } from "whiteboard/AppState";

const Logo = ({ className, children, ...props }) => {
  const { tenant } = useParams();

  const isDarkMode = isNativeDarkMode();
  const { tenantView } = useStoreState((store) => store.tenantBrandingState);

  if (!!tenant && !!tenantView) {
    const tenantLogo = isDarkMode
      ? tenantView["LOGO_DARK"] || tenantView["LOGO_LIGHT"]
      : tenantView["LOGO_LIGHT"];
    return (
      <div className="relative bg-center bg-no-repeat">
        <img
          alt={tenant}
          src={tenantLogo}
          style={{ height: "40px" }}
          className="h-full"
        />
      </div>
    );
  } else {
    return (
      <div
        style={{ height: "40px" }}
        className={`relative w-full bg-no-repeat bg-center ${className}`}
        {...props}
      >
        {children}
      </div>
    );
  }
};
export default Logo;
