import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

const themes = {
  conviva: {
    brand: "#00465E",
    info: "#EB7B56",
  },
};

const ThemeContainer = styled.div`
  ${({ colorScheme }) => {
    return (
      colorScheme &&
      Object.entries(colorScheme).map(([key, value]) => {
        return `
    .bg-${key}{background-color:${value}}
    .text-${key}{color:${value}}
    .border-${key}{border-color:${value}} 
    .hover\\:text-${key}:hover{color:${value}}
    .hover\\:bg-${key}:hover{background-color:${value}}    
    `;
      })
    );
  }}
`;

const TenantTheme = (props) => {
  const { tenant } = useParams();
  const tenantKey = tenant ? tenant.toLowerCase() : "";
  return <ThemeContainer {...props} colorScheme={themes[tenantKey]} />;
};
export default TenantTheme;
