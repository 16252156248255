import PropTypes from "prop-types";
import React from "react";
import { ShowIf } from "../../../components/viewhelpers";
import VirtualConsult from "./VirtualConsult";
import noop from "lodash/noop";
import useLocalization from "../../../hooks/useLocalization";
import ScheduledAppointments from "../ScheduledAppointments/ScheduledAppointments";

const TechCheckResult = ({
  setIsOpenModal,
  scheduledAppointments,
  tenantKey,
  appointmentDate,
  currentLanguage,
}) => {
  const [t] = useLocalization();
  return (
    <div
      className=" flex flex-col items-center justify-center min-w-full px-0"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <div className="sm:pt-10 text-center">
        <div className="mt-4 font-bold text-2xl sm:text-4xl">
          {t("techCheckValidateDevice.deviceCheck")}
        </div>
      </div>
      <VirtualConsult
        tenantKey={tenantKey}
        setIsOpenModal={setIsOpenModal}
        currentLanguage={currentLanguage}
      />
      <ShowIf
        test={
          scheduledAppointments?.appointments &&
          scheduledAppointments?.techCheckViewConfig?.isAppointmentInfoVisible
        }
      >
        <div className="flex flex-col mt-20 mb-4">
          <div className="flex font-semibold lg:px-4 md:px-4 sm:px-6 xs:px-6 xsm:px-2 lg:text-base md:text-base sm:text-xs xs:text-xs xsm:text-xsm">
            <span className="p-1 my-auto">
              {t("techCheckValidateDevice.confirmArrival")}
            </span>{" "}
            <span className="my-auto">{appointmentDate}</span>
          </div>
          <ScheduledAppointments
            scheduledAppointments={scheduledAppointments}
          />
          <div className="flex px-4 font-semibold lg:px-4 md:px-4 sm:px-6 xs:px-4 xsm:px-2 lg:text-base md:text-base sm:text-xs xs:text-xs xsm:text-xsm">
            {t("techCheckValidateDevice.contactInfo")}
          </div>
        </div>
      </ShowIf>
    </div>
  );
};

export default TechCheckResult;

TechCheckResult.propTypes = {
  setIsOpenModal: PropTypes.func,
  scheduledAppointments: PropTypes.shape({}),
  tenantKey: PropTypes.string,
  appointmentDate: PropTypes.string,
};

TechCheckResult.defaultProps = {
  setIsOpenModal: noop,
};
