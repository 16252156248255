import React from "react";

const ChargingDevice = ({ header, instruction, charging, charged, image }) => {
  return (
    <div className="flex flex-col items-center">
      <p className="mb-1 font-medium">{header}</p>
      <img className="mx-auto my-1" src={image} alt="device" />
      <p className="my-1 italic text-center w-64">{instruction}</p>
      <div className="mt-2 mb-4 font-medium ">
        <p className="m-0">{charging}</p>
        <p className="m-0">{charged}</p>
      </div>
    </div>
  );
};

export default ChargingDevice;
