import styled, { keyframes } from "styled-components";

import React from "react";
import AccountCircleIconBase from "@mui/icons-material/AccountCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AlarmOffBase from "@mui/icons-material/AlarmOff";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIconBase from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ClearIconBase from "@mui/icons-material/Close";
import CloseIconBase from "@mui/icons-material/Close";
import { ReactComponent as CloudDownload } from "images/cloud-upload.svg";
import ExpandLessIconBase from "@mui/icons-material/ExpandLess";
import ExpandMoreIconBase from "@mui/icons-material/ExpandMore";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FavoriteBorderIconBase from "@mui/icons-material/FavoriteBorder";
import LanguageIconBase from "@mui/icons-material/Language";
import LaunchIconBase from "@mui/icons-material/Launch";
import LinkBase from "@mui/icons-material/Link";
import SearchIconBase from "@mui/icons-material/Search";
import VisibilityIconBase from "@mui/icons-material/Visibility";
import VisibilityOffIconBase from "@mui/icons-material/VisibilityOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const MenuIcon = ({ className, ...props }) => {
  return (
    <svg
      className="w-3 h-3 fill-current"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Menu</title>
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
  );
};

export const circularAnimation = keyframes`
  0% {
    transform : rotate(0deg)
  }
  100% {
    transform : rotate(360deg)
  }
`;

export const CirclularLoading = styled.div`
  animation: ${circularAnimation} 1s ease-in-out infinite;
`;

export const LinkIcon = LinkBase;
export const ExpandMoreIcon = ExpandMoreIconBase;
export const ExpandLessIcon = ExpandLessIconBase;
export const AccountCircleIcon = AccountCircleIconBase;
export const AddCircledIcon = AddCircleOutlineOutlinedIcon;
export const DeleteOutlineIcon = DeleteOutlineOutlinedIcon;
export const ClearIcon = ClearIconBase;
export const CloseIcon = CloseIconBase;
export const SearchIcon = SearchIconBase;
export const LaunchIcon = LaunchIconBase;
export const FavoriteBorderIcon = FavoriteBorderIconBase;
export const CheckCircleOutlined = CheckCircleOutlineRoundedIcon;
export const CancelCircleOutlined = CancelOutlinedIcon;
export const CheckCircleIcon = CheckCircleIconBase;
export const VisibilityIcon = VisibilityIconBase;
export const VisibilityOffIcon = VisibilityOffIconBase;
export const LanguageIcon = LanguageIconBase;
export const AlarmOffIcon = AlarmOffBase;
export const CloudDownloadOutlineIcon = CloudDownload;
export const ErrorOutline = ErrorOutlineIcon;
