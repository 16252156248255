import React from "react";
import SafariBrowserIcon from "../../../images/Safari.png";
import chromeBrowserIcon from "../../../images/Chrome.png";
import edgeBrowserIcon from "../../../images/Edge.png";
import useLocalization from "../../../hooks/useLocalization";
import wifiarrow from "../../../images/arrow-wifi.svg";

const RenderTechCheckView = ({ children }) => {
  const [t, changeLanguage] = useLocalization();
  return (
    <div>
      <div className="mx-auto flex flex-col text-center items-center">
        <div className="mx-8">
          <div className="my-4 pt-4 text-2xl font-bold text-center sm:text-4xl ">
            {t("techCheckValidateDevice.title")}
          </div>
          <div className=" flex-initial items-left mx-auto max-w-2xl">
            <div className="">
              <div className="flex flex-no-wrap px-1 gap-2 sm:gap-5">
                <div className="float-left flex-shrink-0 ">
                  <img src={wifiarrow} alt="" />
                </div>
                <p className="float-right text-left text-sm sm:text-base">
                  {t("techCheckValidateDevice.netWork")}
                </p>
              </div>

              <div className="flex flex-no-wrap px-1 gap-2 sm:gap-5">
                <div className="float-left flex-shrink-0 ">
                  <img src={wifiarrow} alt="" />
                </div>
                <p className="float-right text-left text-sm sm:text-base">
                  {t("techCheckValidateDevice.windows")}
                </p>
              </div>

              <div className="flex flex-no-wrap px-1 gap-2 sm:gap-5">
                <div className="float-left flex-shrink-0 ">
                  <img src={wifiarrow} alt="" />
                </div>
                <p className="float-right text-left text-sm sm:text-base">
                  {t("techCheckValidateDevice.apple")}
                </p>
              </div>

              <div className="flex flex-no-wrap px-1 gap-2 sm:gap-5">
                <div className="float-left flex-shrink-0 ">
                  <img src={wifiarrow} alt="" />
                </div>
                <p className="float-right text-left text-sm sm:text-base">
                  {t("techCheckValidateDevice.android")}
                </p>
              </div>
            </div>

            <div className="flex self-center justify-center gap-1 my-4 pb-2 mx-auto sm:my-4">
              <img
                onClick={() =>
                  window.open(
                    "https://www.google.com/chrome/?brand=YTUH&geo=US&gclid=EAIaIQobChMInvDugJSY8wIVjJ-zCh0pOQSuEAAYASAAEgJu8fD_BwE&gclsrc=aw.ds"
                  )
                }
                className="h-1/4 w-1/4  cursor-pointer "
                src={chromeBrowserIcon}
                alt=""
              />
              <img
                onClick={() =>
                  window.open(
                    "https://www.microsoft.com/en-us/edge?brand=M022&OCID=AID2200279_SEM_EAIaIQobChMI4L6sjZSY8wIVUAaICR375AjkEAAYASAAEgINE_D_BwE:G:s&ef_id=EAIaIQobChMI4L6sjZSY8wIVUAaICR375AjkEAAYASAAEgINE_D_BwE:G:s&gclid=EAIaIQobChMI4L6sjZSY8wIVUAaICR375AjkEAAYASAAEgINE_D_BwE"
                  )
                }
                className="h-1/4 w-1/4  cursor-pointer"
                src={edgeBrowserIcon}
                alt=""
              />
              <img
                onClick={() =>
                  window.open("https://support.apple.com/downloads/safari")
                }
                className="h-1/4 w-1/4  cursor-pointer"
                src={SafariBrowserIcon}
                alt=""
              />
            </div>

            <div className="">
              <div className="flex flex-no-wrap px-1 gap-2 sm:gap-5">
                <div className="float-left flex-shrink-0 ">
                  <img src={wifiarrow} alt="" />
                </div>
                <p className="float-right text-left text-sm sm:text-base">
                  {t("techCheckValidateDevice.connectedDevice")}
                </p>
              </div>

              <div className="flex flex-no-wrap px-1 gap-2 sm:gap-5">
                <div className="float-left flex-shrink-0 ">
                  <img src={wifiarrow} alt="" />
                </div>
                <p className="float-right text-left text-sm sm:text-base">
                  {t("techCheckValidateDevice.place")}
                </p>
              </div>
            </div>
          </div>
          <div className="pt-3 pb-4 sm:pb-2">
            <img
              className="w-12 mx-auto"
              style={{ transform: "rotate(90deg)" }}
              src={wifiarrow}
              alt=""
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default RenderTechCheckView;
