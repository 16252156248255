import Lottie from "lottie-react";
import React from "react";
import loadingAnimation from "../images/andor-loading.json";

export const LoadingIndicator = () => {
  const style = {
    height: 90,
    width: 90,
  };
  return <Lottie animationData={loadingAnimation} style={style} />;
};
