import {
  browserName,
  browserVersion,
  isChrome,
  isEdge,
  isFirefox,
  isSafari,
  isSamsungBrowser,
  osName,
} from "react-device-detect";
export const validateDevice = () => {
  let isBrowserSupported = false,
    isOSSupported = false;
  switch (osName) {
    case "Android":
      isOSSupported = true;
      if (isChrome || isSamsungBrowser || browserName === "Samsung Internet") {
        isBrowserSupported = true;
      }
      return { isBrowserSupported, isOSSupported };
    case "iOS":
      isOSSupported = true;
      if (isSafari) {
        if (parseFloat(browserVersion) > 13.1) {
          isBrowserSupported = true;
        }
      }
      if (isChrome) {
        isBrowserSupported = true;
      }
      return { isBrowserSupported, isOSSupported };
    case "Mac OS":
      isOSSupported = true;
      if (isChrome || isSafari || isEdge || isFirefox) {
        isBrowserSupported = true;
      }
      return { isBrowserSupported, isOSSupported };
    case "Windows":
      isOSSupported = true;
      if (isChrome || isEdge) {
        isBrowserSupported = true;
      }
      return { isBrowserSupported, isOSSupported };
    case "Linux":
      isOSSupported = true;
      if (isChrome) {
        isBrowserSupported = true;
      }
      return { isBrowserSupported, isOSSupported };
    default:
      return { isBrowserSupported, isOSSupported };
  }
};
