import React from "react";
import PropTypes from "prop-types";
import { ErrorOutline } from "components/ui/icons";
import useLocalization from "hooks/useLocalization";

const TestDevice = (selfTestUrl) => {
  const [t] = useLocalization();
  return (
    <div
      className="flex flex-col items-center sm:items-start sm:gap-3 mt-2 pt-3 w-full"
      style={{
        borderTop: "solid 2.5px #E4EAEA",
      }}
    >
      <div className="flex flex-no-wrap gap-2 justify-between">
        <ErrorOutline className="m-auto text-warning bg-white rounded-full h-5 w-5" />
        <div className="text-left text-sm sm:text-base sm:text-left font-medium">
          {t("techCheckValidateDevice.priorToVisit")}
        </div>
      </div>

      <button
        onClick={() => window.open(selfTestUrl?.selfTestUrl)}
        className="text-sm sm:text-base cursor-pointer underline underline-offset-2 sm:pl-6"
        style={{ color: "#145D61" }}
      >
        {t("techCheckValidateDevice.testDevice")}
      </button>
    </div>
  );
};

export default TestDevice;

TestDevice.propTypes = {
  selfTestUrl: PropTypes.string,
};
