import React, { useEffect, useState } from "react";
import {
  browserName,
  browserVersion,
  isChrome,
  isEdge,
  isFirefox,
  isSafari,
  isSamsungBrowser,
  osName,
  osVersion,
} from "react-device-detect";
import LatestOSBrowser from "./VirtualConsultComponents/LatestOSBrower";
import { LoadingIndicator } from "../../LoadingIndicator";
import PropTypes from "prop-types";
import TestDevice from "./VirtualConsultComponents/TestDevice";
import noop from "lodash/noop";
import { useStoreState } from "whiteboard/AppState";
import useLocalization from "../../../hooks/useLocalization";

const VirtualConsultComponent = ({
  tenantKey,
  currentLanguage,
  setIsOpenModal,
}) => {
  const { selfTestUrl } = useStoreState((store) => store.tenantBrandingState);
  const [show, setShow] = useState(false);
  let osSupported = false,
    browserSupported = false;
  const [t] = useLocalization();
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [show]);

  const DeviceCheckMessage = () => {
    return (
      <div className="flex flex-col text-base mb-3 font-medium sm:text-xl max-w-3xl">
        {browserSupported && osSupported && (
          <span>{t("techCheckValidateDevice.messageSuccessDeviceCheck")}</span>
        )}
        {tenantKey === "musc" ? (
          <span>{t("techCheckValidateDevice.muscMessageNextStep")}</span>
        ) : tenantKey === "tide" ? (
          <span>{t("techCheckValidateDevice.tideMessageNextStep")}</span>
        ) : (
          <span>{t("techCheckValidateDevice.messageNextStep")}</span>
        )}
      </div>
    );
  };

  if (!show) {
    return (
      <div className="flex flex-col items-center py-2">
        <div className="flex flex-col text-base font-medium sm:text-xl max-w-3xl">
          <span> {t("techCheckValidateDevice.prepareConsult")}</span>
          <span>{t("techCheckValidateDevice.upcomingVisit")}</span>
        </div>
        <LoadingIndicator />
      </div>
    );
  }

  switch (osName) {
    case "Android":
      osSupported = true;
      if (isChrome || isSamsungBrowser || browserName === "Samsung Internet") {
        browserSupported = true;
      }
      return (
        <div className="flex flex-col items-center p-3 w-full">
          <DeviceCheckMessage />
          <LatestOSBrowser
            osSupported={osSupported}
            browserSupported={browserSupported}
            setIsOpenModal={setIsOpenModal}
          >
            {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
          </LatestOSBrowser>
        </div>
      );
    case "iOS":
      return isSafari || isChrome ? (
        parseFloat(browserVersion) > 13.1 || isChrome ? (
          (parseFloat(osVersion) === 16.0 && isSafari) ||
          (parseFloat(osVersion) === 16.1 && isSafari) ? (
            <div className="flex flex-col items-center p-3 w-full">
              <DeviceCheckMessage />
              <LatestOSBrowser
                osSupported={true}
                browserSupported={false}
                setIsOpenModal={setIsOpenModal}
              >
                {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
              </LatestOSBrowser>
            </div>
          ) : (
            <div className="flex flex-col items-center p-3 w-full">
              <DeviceCheckMessage />
              <LatestOSBrowser
                osSupported={true}
                browserSupported={true}
                setIsOpenModal={setIsOpenModal}
              >
                {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
              </LatestOSBrowser>
            </div>
          )
        ) : (
          <div className="flex flex-col items-center p-3 w-full">
            <DeviceCheckMessage />
            <LatestOSBrowser
              osSupported={true}
              browserSupported={false}
              setIsOpenModal={setIsOpenModal}
            >
              {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
            </LatestOSBrowser>
          </div>
        )
      ) : (
        <div className="flex flex-col items-center p-3 w-full">
          <DeviceCheckMessage />
          <LatestOSBrowser
            osSupported={true}
            browserSupported={false}
            setIsOpenModal={setIsOpenModal}
          >
            {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
          </LatestOSBrowser>
        </div>
      );
    case "Mac OS":
      osSupported = true;
      if (isChrome || isSafari || isEdge || isFirefox) {
        browserSupported = true;
      }
      return (
        <div className="flex flex-col items-center p-3 w-full">
          <DeviceCheckMessage />
          <LatestOSBrowser
            osSupported={osSupported}
            browserSupported={browserSupported}
            setIsOpenModal={setIsOpenModal}
          >
            {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
          </LatestOSBrowser>
        </div>
      );
    case "Windows":
      osSupported = true;
      if (isChrome || isEdge) {
        browserSupported = true;
      }
      return (
        <div className="flex flex-col items-center p-3 w-full">
          {" "}
          <DeviceCheckMessage />
          <LatestOSBrowser
            osSupported={osSupported}
            browserSupported={browserSupported}
            setIsOpenModal={setIsOpenModal}
          >
            {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
          </LatestOSBrowser>
        </div>
      );
    case "Linux":
      osSupported = true;
      if (isChrome) {
        browserSupported = true;
      }
      return (
        <div className="flex flex-col items-center p-3 w-full">
          {" "}
          <DeviceCheckMessage />
          <LatestOSBrowser
            osSupported={osSupported}
            browserSupported={browserSupported}
            setIsOpenModal={setIsOpenModal}
          >
            {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
          </LatestOSBrowser>
        </div>
      );
    default:
      return (
        <div className="flex flex-col items-center p-3 w-full">
          {" "}
          <DeviceCheckMessage />
          <LatestOSBrowser
            osSupported={osSupported}
            browserSupported={browserSupported}
          >
            {selfTestUrl && <TestDevice selfTestUrl={selfTestUrl} />}
          </LatestOSBrowser>
        </div>
      );
  }
};

export default VirtualConsultComponent;

VirtualConsultComponent.propTypes = {
  currentLanguage: PropTypes.string,
  setIsOpenModal: PropTypes.func,
  tenantKey: PropTypes.string,
};

VirtualConsultComponent.defaultProps = {
  setIsOpenModal: noop,
};
