import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ThinkAndorButton } from "../ui/buttons";
import useLocalization from "hooks/useLocalization";
import { InviteUserAddIcon } from "./styling";
import noop from "lodash/noop";

const InviteParticipantButton = ({ handleInvite }) => {
  const [t] = useLocalization();
  return (
    <div className="flex flex-col sm:flex-row items-center justify-center w-full px-4 pt-8 pb-16 gap-3 bg-[#F6F6F6]">
      <InviteUserAddIcon />
      <div className="sm:text-left sm:max-w-md">
        {t("techCheckValidateDevice.anotherParticipant")}
      </div>
      <ThinkAndorButton
        className={classnames(
          "w-full sm:w-fit flex-shrink-0 whitespace-nowrap text-center font-bold sm:ml-3 "
        )}
        onClick={() => handleInvite()}
      >
        {t("techCheckValidateDevice.inviteText")}
      </ThinkAndorButton>
    </div>
  );
};

export default InviteParticipantButton;

InviteParticipantButton.propTypes = {
  handleInvite: PropTypes.func,
};

InviteParticipantButton.defaultProps = {
  handleInvite: noop,
};
