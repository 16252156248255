import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AndorBGLogo from "components/ui/logo";
import AndorLogo from "components/ui/andorLogo";
import NIH_logo from "images/NIH_logo.svg";
import classnames from "classnames";
import logoSvg from "images/andor-logo-h-light.svg";
import useLink from "hooks/useLink";

export const BaseScreen = ({ className, ...props }) => {
  return (
    <div className={`w-full h-full flex flex-col ${className}`} {...props} />
  );
};

export const ContentAreaCentered = ({ className, ...props }) => {
  return (
    <div
      className={`mx-auto px-8 max-w-sm min-w-full h-full overflow-y-auto ${className}`}
      {...props}
    />
  );
};
export const ContentArea = ({ className, ...props }) => {
  return (
    <div
      className={`mx-auto max-w-sm  h-full w-full ${className}`}
      {...props}
    />
  );
};

export const CenteredScreen = ({ className, ...props }) => {
  return <div className={`mx-auto max-w-sm mb-8 ${className}`} {...props} />;
};

export const NavBar = ({ className, ...props }) => {
  return (
    <nav
      className={`relative flex items-center justify-between p-4 ${className}`}
      {...props}
    />
  );
};

export const TechCheckNavBar = ({ className, style, links }) => {
  return (
    <nav className={`${className} relative flex justify-center`} style={style}>
      {links}
    </nav>
  );
};

export const NihVaccinationPageNavBar = ({ left, right, className }) => {
  return (
    <nav
      className={`${className} bg-card relative flex items-center justify-between p-2`}
    >
      <div className="min-w-1/4">{left}</div>
      <img className="w-32 h-auto" src={NIH_logo} alt="NIH_logo" />
      <div className="text-right min-w-1/4">{right}</div>
    </nav>
  );
};

export const NavBarShared = ({
  left,
  right,
  className,
  tenantKey = null,
  ...props
}) => {
  const [link] = useLink();
  const logo = (
    <AndorBGLogo onClick={link("/")} className="h-5 cursor-pointer" />
  );
  return (
    <NavBar className={`${className} bg-card`}>
      <div className="min-w-1/4">{left}</div>
      {logo}
      <div className="text-right min-w-1/4">{right}</div>
    </NavBar>
  );
};

export const AppBar = ({ label, children, ...props }) => {
  const NavRoot = ({ children, className, ...otherProps }) => (
    <nav
      className={`relative flex items-center justify-between h-16 p-4 ${className}`}
      {...otherProps}
    >
      {children}
    </nav>
  );
  if (children) {
    return <NavRoot {...props}>{children}</NavRoot>;
  }
  return (
    <NavRoot {...props}>
      <p className="absolute z-10 invisible w-full text-xl font-medium text-center pointer-events-none text-brand sm:visible">
        {label}
      </p>
      <div className="" id="public-appbar-left"></div>
      <p
        id="public-appbar-center"
        className="flex-grow visible text-xl font-medium text-left text-brand sm:invisible"
      >
        {label}
      </p>
      <div className="" id="public-appbar-right"></div>
    </NavRoot>
  );
};

export const AppBanner = ({ heading, text, ...props }) => {
  return <div className="px-1 py-3 shadow-sm bg-card" {...props} />;
};

export const FormContainer = ({ label, children, ...props }) => {
  return <div className="w-full" />;
};

export const AlertModal = ({
  isOpen,
  title,
  textContent,
  handleClose,
  ...props
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{textContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          className="focus:outline-none"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TextBanner = ({ className, ...props }) => (
  <div
    className={`my-5 mx-auto max-w-lg p-4 text-center border border-brand shadow-lg ${className}`}
    {...props}
  />
);

export const LoaderModal = ({ isOpen, ...props }) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent style={{ padding: "24px", paddingTop: "24px" }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export const PoweredByAndor = ({ className, ...props }) => (
  <div className="absolute bottom-0 right-0 hidden px-2 py-4 md:block">
    <div className="mb-1 text-xs font-medium text-on-card">Powered By</div>
    <img src={logoSvg} alt="powered by logo" className="h-6" />
  </div>
);

export const PoweredByAndorHorizontal = ({ className, ...props }) => (
  <div className="flex justify-end w-full px-1 py-2 bg-space">
    <div className="text-xs font-medium text-on-card">Powered By</div>
    <AndorLogo />
  </div>
);

export const ToolTip = ({ className, title, content, ...props }) => {
  const [isShown, setIsShown] = useState(false);
  const toolTipRef = useRef(null);

  const handleClickOutside = (e) => {
    if (
      toolTipRef.current &&
      !toolTipRef.current.contains(e.target) &&
      e.target.id !== "tooltip__controller"
    ) {
      if (!isShown) setIsShown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={`relative w-full ${className}`}>
      <div className="relative w-full font-semibold text-on-card">
        <div className="w-full text-right">
          <span
            id="tooltip__controller"
            className="font-bold underline cursor-pointer text-danger text-info"
            onClick={() => setIsShown(!isShown)}
          >
            {title}
          </span>
        </div>
      </div>
      <div
        ref={toolTipRef}
        className={classnames(
          "bg-card text-on-card py-8 px-3 right-0 mt-3 max-w-xs w-full absolute text-center border border-grayish-strong rounded-md",
          { hidden: !isShown }
        )}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
