import "./App.css";
import "./i18n";

import React, { Suspense, lazy, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import { useStoreActions, useStoreState } from "whiteboard/AppState";

import AndroidPage from "./views/FAQPages/AndroidPage";
import AppTheme from "views/AppTheme";
import BlankPage from "./views/pages/BlankPage";
import ComPage from "./views/FAQPages/ComputerPage";
import IOSPage from "./views/FAQPages/IOSPage";
import InvalidLink from "views/pages/InvalidLink";
import { LoadingIndicatorScreen } from "components/viewhelpers";
import LoginForm from "views/pages/LoginForm";
import { StateServiceProvider } from "whiteboard/StateService";
import ThemeType from "components/ThemeType";
import UriCodeRedirect from "components/uriCodeRedirect";
import ValidateDevice from "views/newTechCheck/ValidateDevice";
import DefaultPage from "views/pages/DefaultPage";

const lazyWithMinWait = (importPromise, wait = 1000) => {
  return lazy(() => {
    return Promise.all([
      importPromise,
      new Promise((resolve) => setTimeout(resolve, wait)),
    ]).then(([moduleExports]) => moduleExports);
  });
};

const CaptivePortal = lazyWithMinWait(import("./views/Portals/CaptivePortal"));

const LoadingScreen = () => {
  return <LoadingIndicatorScreen />;
};

const App = () => {
  return (
    <StateServiceProvider>
      <ThemeType>
        {(mode) => (
          <AppTheme themeType={mode}>
            <WaitForInit>
              <Router>
                <Suspense fallback={LoadingScreen()}>
                  <Routes>
                    <Route path="*" element={<DefaultPage />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/blank" element={<BlankPage />} />
                    <Route path="/invalid-link" element={<InvalidLink />} />
                    <Route
                      path="/caregilitytest"
                      element={<ValidateDevice tenantKey="fmolhs" />}
                    />
                    <Route path="/faq/pc" element={<ComPage />} />
                    <Route path="/faq/android" element={<AndroidPage />} />
                    <Route path="/faq/ios" element={<IOSPage />} />
                    <Route path="/q/:uriCode" element={<UriCodeRedirect />} />
                    <Route
                      path="/:tenant/*"
                      element={
                        <StartUpTenant>
                          <CaptivePortal />
                        </StartUpTenant>
                      }
                    />
                  </Routes>
                </Suspense>
              </Router>
            </WaitForInit>
          </AppTheme>
        )}
      </ThemeType>
    </StateServiceProvider>
  );
};

export default App;

// be sure config is loaded before any of the app
function WaitForInit({ children }) {
  const isInit = useStoreState((store) => store.isInit);
  if (isInit) {
    return children;
  }
  return null;
}

export const StartUpTenant = ({ children }) => {
  const { getTenantBranding } = useStoreActions(
    (store) => store.tenantBrandingState
  );
  const { tenant } = useParams();
  const location = useLocation();
  const tenantCode = tenant ? tenant.toLowerCase() : "";
  const parts = tenantCode.split("-");
  const uriCode = location.search.replace("?uriCode=", "");

  useEffect(() => {
    const performInit = async () => {
      await getTenantBranding({
        tenant: parts[0],
        orgSite: parts[1],
        name: "TENANT_GLOBALS",
        uriCode,
      });
    };
    performInit();
  }, []);

  return children;
};
