import React from "react";

export const BaseScreen = ({ className, ...props }) => (
  <div className={`w-full h-full flex flex-col ${className} `} {...props} />
);

export const ContentAreaCenter = ({ className, ...props }) => (
  <div className={`mx-auto max-w-screen-lg w-full ${className} `} {...props} />
);

export const SectionContainer = ({ className, ...props }) => (
  <div className={`my-8 ${className} `} {...props} />
);

export const NavContainer = ({ className, ...props }) => (
  <nav className={`w-full h-20 shadow-sm ${className}`} {...props} />
);

export const Nav = ({ className, ...props }) => (
  <div
    className={`mx-auto max-w-screen-lg flex items-center h-full lg:justify-between justify-center lg:flex-row flex-col lg:px-4 px-2 ${className} `}
    {...props}
  />
);

export const NavLinks = ({ className, ...props }) => (
  <div
    className={`flex-wrap flex flex-row lg:justify-end justify-between w-full ${className}`}
    {...props}
  />
);

export const NavLink = ({ className, children, href = "#", ...props }) => (
  <a
    href={href}
    className={`flex flex-row items-center lg:px-3 px-1 lg:text-base lg:pt-0 pt-4 text-sm ${className}`}
    {...props}
  >
    {children}
  </a>
);

export const VideoContainer = ({ className, ...props }) => (
  <div
    className={` relative lg:w-1/2 w-full lg:h-64 h-48 mx-auto overflow-hidden shadow-2xl ${className}`}
    {...props}
  />
);

export const LinksContainer = ({ className, ...props }) => (
  <div
    className={`flex justify-start w-full flex-wrap ${className}`}
    {...props}
  />
);

export const LinkItem = ({ className, ...props }) => (
  <div className={`lg:w-1/3 w-1/2 p-2 ${className}`} {...props} />
);

export const LinkButton = ({ className, ...props }) => (
  <div
    className={`px-2 flex justify-center items-center w-full rounded-md text-white text-center lg:text-base text-sm shadow-md ${className}`}
    style={{ backgroundColor: "#373F46", minHeight: "63px" }}
    {...props}
  />
);

export const SectionButton = ({ className, icon, label, ...props }) => {
  return (
    <a className="w-full p-2 sm:w-1/2 lg:w-1/3" {...props}>
      <div className="flex items-center justify-center w-full h-20 px-2 text-sm text-center rounded-md shadow-md text-on-brand bg-brand lg:text-base">
        {icon} <span className="flex-grow">{label}</span>
      </div>
    </a>
  );
};
