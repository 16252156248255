import React from "react";
import useLocalization from "hooks/useLocalization";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import FixDeviceLink from "views/newTechCheck/components/VirtualConsultComponents/FixDeviceLink";
import OSBrowserContainer from "views/newTechCheck/components/VirtualConsultComponents/OSBrowserContainer";
import {
  CancelCircleOutlinedIcon,
  CheckCircleOutlinedIcon,
} from "views/newTechCheck/components/VirtualConsultComponents/StyledIcons";
import DividerLine from "views/newTechCheck/components/VirtualConsultComponents/DividerLine";
import {
  StyledCheckIcon,
  StyledCancelIcon,
  DeviceCheckFeedbackContainer,
} from "views/newTechCheck/components/VirtualConsultComponents/styling";

const LatestOSBrowser = ({
  browserSupported,
  osSupported,
  setIsOpenModal,
  children,
}) => {
  const [t] = useLocalization();

  if (browserSupported || osSupported) {
    return (
      <DeviceCheckFeedbackContainer>
        {browserSupported && osSupported ? (
          <>
            <StyledCheckIcon />
            <OSBrowserContainer>
              <CheckCircleOutlinedIcon />
              <div className="text-left mx-1 my-auto">
                {t("techCheckValidateDevice.supportedBrowser")}
              </div>
            </OSBrowserContainer>
            <DividerLine />
            <OSBrowserContainer>
              <CheckCircleOutlinedIcon />
              <div className="text-left mx-1 my-auto">
                {t("techCheckValidateDevice.latestOS")}
              </div>
            </OSBrowserContainer>
            <OSBrowserContainer>{children}</OSBrowserContainer>
          </>
        ) : (
          <>
            <StyledCancelIcon />
            <OSBrowserContainer>
              {browserSupported ? (
                <CheckCircleOutlinedIcon />
              ) : (
                <CancelCircleOutlinedIcon />
              )}
              <div className="text-left mx-1 my-auto flex gap-2">
                {browserSupported ? (
                  t("techCheckValidateDevice.supportedBrowser")
                ) : (
                  <>
                    {t("techCheckValidateDevice.unsupportedBrowser")}
                    <FixDeviceLink onLinkClick={setIsOpenModal} />
                  </>
                )}
              </div>
            </OSBrowserContainer>
            <DividerLine />
            <OSBrowserContainer>
              {osSupported ? (
                <CheckCircleOutlinedIcon />
              ) : (
                <CancelCircleOutlinedIcon />
              )}
              <div className="text-left mx-1 my-auto flex gap-2">
                {osSupported ? (
                  t("techCheckValidateDevice.latestOS")
                ) : (
                  <>
                    {t("techCheckValidateDevice.notLatestOS")}
                    <FixDeviceLink onLinkClick={setIsOpenModal} />
                  </>
                )}
              </div>
            </OSBrowserContainer>
            <OSBrowserContainer>{children}</OSBrowserContainer>
          </>
        )}
      </DeviceCheckFeedbackContainer>
    );
  } else {
    return (
      <DeviceCheckFeedbackContainer>
        <StyledCancelIcon />
        <div className="text-sm sm:text-base sm:text-left font-medium mb-3">
          {t("techCheckValidateDevice.unsupportedMessage")}
        </div>
        <OSBrowserContainer>
          <div className="flex flex-col items-center sm:flex-row sm:gap-3">
            <div className="flex flex-no-wrap gap-2 justify-between">
              <CancelCircleOutlinedIcon />
              <div className="text-left">
                {t("techCheckValidateDevice.unsupportedBrowser")}
              </div>
            </div>
            <FixDeviceLink onLinkClick={setIsOpenModal} />
          </div>
        </OSBrowserContainer>
        <DividerLine />
        <OSBrowserContainer>
          <div className="flex flex-col items-center sm:flex-row sm:gap-3">
            <div className="flex flex-no-wrap gap-2 justify-between">
              <CancelCircleOutlinedIcon />
              <div className="text-left">
                {t("techCheckValidateDevice.notLatestOS")}
              </div>
            </div>
            <FixDeviceLink onLinkClick={setIsOpenModal} />
          </div>
        </OSBrowserContainer>
      </DeviceCheckFeedbackContainer>
    );
  }
};

export default LatestOSBrowser;

LatestOSBrowser.propTypes = {
  supported: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
};

LatestOSBrowser.defaultProps = {
  setIsOpenModal: noop,
};
