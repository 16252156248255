import React, { useEffect } from "react";
import { StoreProvider, action, createStore, persist, thunk } from "easy-peasy";

import AppState from "whiteboard/AppState";
import FormState from "whiteboard/FormState";
import HealthSearchState from "whiteboard/HealthSearchState";
import { LanguageChangeState } from "./LanguageChangeState";
import PreVisitState from "whiteboard/PreVisitState";
import { TechCheckState } from "./TechCheckState";
import TenantBrandingState from "whiteboard/TenantBrandingState";
import TriageState from "whiteboard/TriageState";

export const store = createStore({
  app: AppState,
  triage: TriageState,
  healthSearch: HealthSearchState,
  formState: FormState,
  preVisitState: PreVisitState,
  tenantBrandingState: TenantBrandingState,
  languageChangeState: persist(LanguageChangeState),
  techCheckState: TechCheckState,
  isReady: false,
  isInit: false,
  performInit: thunk(async (actions, payload) => {
    await store.dispatch.app.performInit(store);
    actions.setAppInit();
  }),
  setAppInit: action((state) => {
    state.isInit = true;
  }),
  setAppReady: action((state) => {
    state.isReady = true;
  }),
});

export const StateServiceProvider = ({ children }) => {
  useEffect(() => {
    store.dispatch.performInit();
  });
  return <StoreProvider store={store}>{children}</StoreProvider>;
};
