import "../App.css";

import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import React from "react";
import { StylesProvider } from "@mui/styles";
import { ThemeProvider } from "styled-components";
import makeTheme from "../theme";

const AppTheme = ({ children, themeType = "light" }) => {
  const theme = makeTheme(themeType);

  return (
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

export default AppTheme;
