import React from "react";

const DividerLine = () => {
  return (
    <div
      className="w-full my-2 p-0"
      style={{
        border: "solid 1px #E4EAEA",
      }}
    ></div>
  );
};

export default DividerLine;
