import React from "react";
import PropTypes from "prop-types";
import { sanitize } from "dompurify";
import { getShared } from "./styling";
import classNames from "classnames";

const Select = ({
  children,
  selectType,
  disabled = false,
  width,
  ...props
}) => {
  const shared = getShared(props);
  const value = props.value || "";
  const { className, error, ...inputProps } = props;
  return (
    <div className={`relative inline-block ${width} mb-2`}>
      <select
        className={classNames(
          ` appearance-none w-full ${shared} px-2 py-2 pr-6 rounded focus:outline-none focus:shadow-outline  ${className} `,
          {
            "block bg-card border hover:border-gray-500 bg-card shadow-md focus:shadow-outline":
              selectType !== "new",
            "bg-gray-100": selectType === "new",
            "border border-danger": selectType === "new" && error,
          }
        )}
        {...inputProps}
        value={sanitize(value)}
        disabled={disabled}
      >
        {children}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg
          className="w-4 h-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export default Select;

Select.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disabled: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inputProps: PropTypes.object,
  border: PropTypes.string,
  selectType: PropTypes.string,
};

Select.defaultProps = {
  disabled: false,
  width: "w-full",
  border: "border",
};
