import styled from "styled-components";
import {
  CancelCircleOutlined,
  CheckCircleOutlined,
  ErrorOutline,
} from "components/ui/icons";

export const DeviceCheckFeedbackContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: start;
  justify-content: space-between;
  border-radius: 15px;
  padding: 2.25rem;
  margin: 1.5rem 0.75rem;
  background-color: white;
  box-shadow: 0 3px 6px rgb(0 0 16 / 0.2);
  width: 100%;
  max-width: 48rem;
  @media (min-width: 0px) and (max-width: 639.9px) {
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1rem;
  }
`;
export const StyledCheckIcon = styled(CheckCircleOutlined)`
  color: rgb(var(--color-brand));
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  background: white;
  border-radius: 50%;
  left: -0.75rem;
  top: -0.75rem;
  @media (min-width: 0px) and (max-width: 639.9px) {
    width: 4rem;
    height: 4rem;
    left: 50%;
    margin-left: -2rem;
    top: -2rem;
  }
`;

export const StyledCancelIcon = styled(CancelCircleOutlined)`
  color: #f88787;
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  background: white;
  border-radius: 50%;
  left: -0.75rem;
  top: -0.75rem;
  @media (min-width: 0px) and (max-width: 639.9px) {
    width: 4rem;
    height: 4rem;
    left: 50%;
    margin-left: -2rem;
    top: -2rem;
  }
`;

export const StyledWarningIcon = styled(ErrorOutline)`
  color: #f8d200;
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  background: white;
  border-radius: 50%;
  left: -0.75rem;
  top: -0.75rem;
  @media (min-width: 0px) and (max-width: 639.9px) {
    width: 4rem;
    height: 4rem;
    left: 50%;
    margin-left: -2rem;
    top: -2rem;
  }
`;
