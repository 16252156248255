import React, { forwardRef } from "react";
import { sanitize } from "dompurify";
import { getShared } from "./styling";
import classnames from "classnames";

export default forwardRef((props, ref) => {
  const shared = getShared(props);
  const value = props.value || "";
  const {
    className,
    error,
    maxlength,
    minlength,
    marginRight = true,
    border = true,
    ...inputProps
  } = props;
  return (
    <input
      ref={ref}
      className={classnames(
        `disabled:opacity-50 appearance-none ${shared} shadow-md rounded w-full mb-2 py-2 px-4 text-on-card focus:outline-none focus:shadow-outline ${className}`,
        { "mr-2": marginRight, border: border }
      )}
      maxLength={maxlength}
      minLength={minlength}
      {...inputProps}
      value={sanitize(value)}
    />
  );
});
