import React from "react";

export const isNativeDarkMode = () => {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

const ThemeType = ({ children }) => {
  const mode = isNativeDarkMode() ? "dark" : "light";
  return <React.Fragment>{children(mode)}</React.Fragment>;
};

export default ThemeType;
