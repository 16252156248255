import PropTypes from "prop-types";
import React from "react";
import { StyledTable } from "./styling";
import { dynamicText } from "../../../utils/StringUtils";

const Appointment = ({ data, columns }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {columns?.map((column, index) => (
            <>
              {column?.isVisible && (
                <TableHeadItem key={index} column={column} />
              )}
            </>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.appointments?.map((item, index) => {
          return <TableRow key={index} item={item} columns={columns} />;
        })}
      </tbody>
    </StyledTable>
  );
};

const TableHeadItem = ({ column }) => <th>{column.header}</th>;
const TableRow = ({ item, columns }) => {
  return (
    <tr>
      {columns?.map((columnItem, index) => {
        const value = dynamicText(item, columnItem.value, "");
        return <>{columnItem?.isVisible && <td key={index}>{value}</td>}</>;
      })}
    </tr>
  );
};

export default Appointment;

Appointment.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
};
