import React from "react";
import { CancelCircleOutlined, CheckCircleOutlined } from "components/ui/icons";

export const CancelCircleOutlinedIcon = () => {
  return (
    <CancelCircleOutlined className="text-danger bg-white rounded-full h-5 w-5" />
  );
};
export const CheckCircleOutlinedIcon = () => {
  return (
    <CheckCircleOutlined className="text-brand bg-white rounded-full h-5 w-5" />
  );
};
