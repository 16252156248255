import Appointment from "./Appointment";
import React from "react";

const ScheduledAppointments = ({ scheduledAppointments }) => {
  const columns = scheduledAppointments?.techCheckViewConfig?.columns || [];
  const data = scheduledAppointments ? scheduledAppointments : {};

  return <Appointment columns={columns} data={data} />;
};

export default ScheduledAppointments;
