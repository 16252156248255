import styled from "styled-components";

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0px 10px;
  max-width: 900px;
  margin: 12px auto;
  width: auto;

  thead th {
    background-color: #e0dede;
    padding: 8px 20px;
    cursor: pointer;
    font-size: 15px;
  }

  thead th:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  thead th:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  tbody tr {
    border: 1px solid #e0dede;
    background-color: #e0dede;
  }

  td {
    padding: 8px 20px;
    border: solid 1px #e0dede;
    border-style: solid none;
    text-align: center;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin: 2px 0px;
    tr td {
      font-size: 9px;
      padding: 8px 22px;
    }
    th {
      font-size: 8px !important;
      text-align: center;
    }
    thead th {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 12px;
      padding-left: 12px;
      max-width: 0;
      :hover {
        text-overflow: clip;
        white-space: normal;
        height: auto;
      }
    }
  }
`;
