import { computed } from "easy-peasy";

const defaultData = {
  title: "How to Prepare for Your Conviva Video Visit",
  pages: [
    {
      id: 0,
      title: "What you need to know",
      sections: [
        {
          id: 0,
          name: "What you need to know",
          value:
            "Our Conviva Care team has added video visit services in response to the COVID-19 pandemic. Now, our providers are able to support your health care needs virtually, because it is important for you to have easy access to your primary care physician and other members of your care team. This service will allow you to continue to practice physical distancing while receiving the same quality of care you get at your Conviva Care Center from the comfort of your home. <br/><br/> It is now easier than ever to have a successful Conviva Video Visit. Whether you are new to video visits, or you have met with a doctor virtually before, follow these guidelines and tips to make the most of your upcoming appointment.",
          type: "HTML",
        },
        {
          id: 1,
          caption: "Click on one of the sections below to learn more!",
          value: [
            {
              id: 0,
              name: "Before your Conviva Video Visit",
              content:
                '2 Days Prior to the Appointment:<br/><ul  style="list-style-type:circle;list-style-position: inside;"><li>You will receive a text and email message reminding you of your upcoming visit with Conviva</li><li>The text and email message will contain a clickable link that directs you to a webpage where you can learn how to prepare for your visit</li><li>If you opted out of emails from Conviva, you will only receive the text message with the link</li><li>If your appointment is scheduled for the same or next day, you will receive the text and email message at the time of scheduling.</li></ul>',
            },
            {
              id: 1,
              name: "On the day of your Conviva Video Visit",
              content:
                "30 Minutes Prior to the Appointment Time, you will receive a text and email message with a clickable link that directs you to a virtual waiting room webpage where you can begin preparing for your visit.<br/><br/>A welcome message will appear on the virtual waiting room webpage. You will have the option of inviting a family member or care giver who is not physically present to join your virtual visit from their own smartphone or computer. Adding a family member is optional.<br/><br/>When your provider is ready to begin your video visit, you will receive a notification in the virtual waiting room. Follow the prompts to start the video connection.",
            },
            {
              id: 3,
              name: "After your Conviva Video Visit",
              content:
                'After your video visit consultation with your doctor:<br/><ul  style="list-style-type:circle;list-style-position: inside;"><li>A Conviva care team member will join your video call or call you after the visit to help you schedule your next appointment and provide assistance.</li><li>If you have questions or need additional assistance arranging specialist referrals, confirming your pharmacy prescriptions, or following your doctor\'s instructions, please call your Conviva Care Center and a team member will assist you.</li></ul>',
            },
          ],
          type: "collapse",
        },
      ],
    },
    {
      id: 1,
      title: "What's a video visit like?",
      sections: [
        {
          id: 0,
          name: "What's a video visit like?",
          value:
            'A video visit, also referred to as "telehealth," "telemedicine," or a "virtual visit," is like a face-to-face office visit with your provider, but via secure video from the comfort of your home or other convenient location. As with any Conviva Care Center visit, your provider will be there to review your symptoms, answer questions, review concerns, and discuss next steps.',
          type: "HTML",
        },
      ],
    },
    {
      id: 2,
      title: "Setting up your technology",
      sections: [
        {
          id: 0,
          name: "Setting up your technology",
          value:
            "You can have a Conviva Video Visit on any smartphone or tablet, including an iPhone, Android, or iPad. You can also use a laptop or any computer that has internet access, a two-way camera, a speaker, and a microphone. <br/><br/>Make sure your device is plugged in or fully charged and that your internet connection is working.<br/><br/>To get ready and be on time for your visit, we recommend you set up your chosen device at least 30 minutes before your appointment and wait in the virtual waiting room for your provider.<br/><br/>Make sure to have your assistive devices, such as a hearing aid or glasses, readily available for the visit.",
          type: "HTML",
        },
      ],
    },
    {
      id: 3,
      title: "Prepare a space and get comfortable",
      sections: [
        {
          id: 0,
          name: "Prepare a space and get comfortable",
          value:
            "Choose a quiet and private space for your Conviva Video Visit that is free from distractions.<br/><br/> Set your camera so it is at eye level for a full view of your face. If you are using a smartphone or tablet, lean it against something so you do not have to hold it. <br/><br/>Adjust your lighting and close any blinds. Try to make sure there is no light or window directly behind the camera to avoid glare.",
          type: "HTML",
        },
      ],
    },
    {
      id: 4,
      title: "Include your significant other or caregiver if desired",
      sections: [
        {
          id: 0,
          name: "Include your significant other or caregiver if desired",
          value:
            "If you would like to have your spouse, partner, or other caregiver join you for your Conviva Video Visit, you are welcome to do so. If that person is not physically with you, they can join the Conviva Video Visit with their own device.<br/><br/>Add your caregiver’s cell phone number while in the virtual waiting room when prompted or have your caregiver’s cell phone number ready so they can be added to the video visit.",
          type: "HTML",
        },
      ],
    },
    {
      id: 5,
      title: "Prepare to talk about your health",
      sections: [
        {
          id: 0,
          name: "Prepare to talk about your health",
          value:
            "Write down any questions you have for your provider ahead of time.<br/><br/>Gather or make a list of all your current medications and dosages so you have them available during the visit.<br/><br/>Share any changes in personal or family health history since your last visit.<br/><br/>During your visit, you may want to show your provider any rashes or wounds that you have questions about. Be prepared to adjust your camera and follow your provider’s instructions or have your spouse or caregiver nearby so they can help hold the camera.<br/><br/>If you have been seen in the emergency room, urgent care, or been admitted to the hospital since you last saw your doctor, you can prepare to discuss any discharge information you received.",
          type: "HTML",
        },
      ],
    },
    {
      id: 6,
      title: "During and after your video visit",
      sections: [
        {
          id: 0,
          name: "During your video visit",
          value:
            "Take time to review and ask questions about any new or existing treatment plan for your health conditions so you can make sure you understand every aspect of your care.<br/><br/>Write down notes so you do not forget the advice your doctor shared with you.",
          type: "HTML",
        },
        {
          id: 1,
          name: "After your video visit",
          value:
            "A Conviva care team member will join your video call or call you after the visit to help you schedule your next appointment and coordinate any necessary follow up. <br/><br/>If you need additional assistance arranging specialist referrals, confirming your pharmacy prescriptions, or following your doctor’s instructions, please call your Conviva Care Center and a team member will assist you.",
          type: "HTML",
        },
        {
          id: 2,
          value:
            "<div class='flex justify-between w-full flex-col'><a class='font-semibold bg-brand text-on-brand text-center rounded py-4' target='_blank'  href='https://www.convivacarecenters.com/en'>Visit Conviva Care Centers™ Website</a></div>",
          type: "HTML",
        },
        {
          id: 3,
          value:
            "<div class='flex justify-between font-normal text-sm italic'>If you have any questions, please contact your Conviva Care Center and a Care Team member will assist you.</div>",
          type: "HTML",
        },
      ],
    },
  ],
  tooltip: {
    title: "Need Help?",
    content:
      'Please call your Conviva Care Center and a Team Member will assist you. <br/><br/><a href="https://www.convivacarecenters.com/en/our_locations" target="_blank" class = "underline font-bold">Click here to find your Conviva Care Center  phone number</a> <br/><br/><a href="https://www.convivacarecenters.com/en" target="_blank"  class = "underline font-bold">Click Here to Visit the Conviva Website</a>',
  },
};

const healthFirstData = {
  title: "Tips to Prepare for Your Virtual Visits",
  pages: [
    {
      id: 0,
      title: "What to Expect",
      sections: [
        {
          id: 0,
          name: "What to Expect",
          value:
            "Virtual Visits bring quality care from your trusted Health First providers to you, <i>wherever you are</i>. <br/> <br/> Whether it’s preventative or routine appointments with your primary care provider, keeping your health on track with your specialty care provider, or a same day visit so that you can start feeling better, we are just one click away. <br/> <br/>Below are a few simple tips to help you prepare for your upcoming appointment. Click on each section to learn more.",
          type: "HTML",
        },
        {
          id: 1,

          value: [
            {
              id: 0,
              name: "Prior to Your Virtual Visit",
              content:
                '2 days prior to your appointment, you will receive a text message and/or an email from Andor Health reminding you of your upcoming visit.<br/><br/><ul  style="list-style-type:circle;list-style-position: inside;"><li>Your reminder will contain a secure link that allows you to confirm your appointment, complete your pre-registration, check-in for your visit and process your payment</li><li>The message will be shared according to your selected contact preference with Health First</li><li>If your appointment is scheduled for the same or next day, you will receive this message at the time of scheduling</li></ul>',
            },
            {
              id: 1,
              name: "Day of Your Virtual Visit",
              content:
                '30 minutes prior to your appointment we will send you a reminder with a secure link to enter our virtual waiting room. Once you’re in the waiting room, you will:<br/><br/> <ul  style="list-style-type:circle;list-style-position: inside;"><li>Have the option to invite a family member or caregiver to join your Virtual Visit from their own smartphone, tablet or computer</li><li>Receive a notification when your provider is ready to see you. Simply follow the prompts to start your Virtual Visit.</li></ul>',
            },
          ],
          type: "collapse",
        },
      ],
    },
    {
      id: 1,
      title: "What You’ll Need",
      sections: [
        {
          id: 0,
          name: "What You’ll Need",
          value:
            '<ul  style="list-style-type:circle;list-style-position: inside;"><li>A Virtual Visit is a face-to-face appointment with your provider, conducted through a secure video platform.<br/><br/></li><li>In order for your provider to successfully diagnose and treat your symptoms, a video connection is required.<br/><br/></li><li>To participate in a Virtual Visit you will need a smartphone, tablet or computer with a working camera, speaker and microphone, as well as an internet connection.<br/><br/></li></ul>',
          type: "HTML",
        },
      ],
    },
    {
      id: 2,
      title: "Setting Up Your Technology",
      sections: [
        {
          id: 0,
          name: "Setting Up Your Technology",
          value:
            'Prior to your Virtual Visit, please make sure your: <br/><br/><ol  style="list-style-type:circle;list-style-position: inside;"><li>Smartphone, tablet or computer is charged and/or plugged in<br/><br/></li><li>Internet connection is working<br/><br/></li><li>Camera, speaker and microphone are turned on<br/><br/></li></ol>',
          type: "HTML",
        },
      ],
    },
    {
      id: 3,
      title: "Find a Comfortable Space",
      sections: [
        {
          id: 0,
          name: "Find a Comfortable Space",
          value:
            'For the most successful Virtual Visit experience we recommend: <br/><br/><ol  style="list-style-type:circle;list-style-position: inside;"><li>A quiet space that is free from distractions<br/><br/></li><li>Avoiding lights and windows directly behind you<br/><br/></li><li>Setting your camera at eye level for a full view of your face<br/><br/></li><li>Leaning your smartphone or tablet against something so that you don’t have to hold it (if applicable)</li></ol>',
          type: "HTML",
        },
      ],
    },
    {
      id: 4,
      title: "Invite Family or Caregiver",
      sections: [
        {
          id: 0,
          name: "Invite Family or Caregiver",
          value:
            "If you would like to invite a family member or caregiver to join your Virtual Visit you will have the opportunity to do so once you’ve entered the waiting virtual waiting room.<br/><br/>If that person is not physically with you, simply add their cell phone number or email address when prompted and they will receive an invitation to join your Virtual Visit, just as if they were sitting in the room with you.",
          type: "HTML",
        },
      ],
    },
    {
      id: 5,
      title: "Bring Your Notes",
      sections: [
        {
          id: 0,
          name: "Bring Your Notes",
          value:
            '<ol  style="list-style-type:circle;list-style-position: inside;"><li>Write down any questions or notes you have for your provider<br/><br/></li><li>Have a list of all your current medications and dosages<br/><br/></li><li>Include any changes in personal or family health history since your last visit, including urgent care or emergency room visits and/or hospitalizations</li></ol>',
          type: "HTML",
        },
      ],
    },
    {
      id: 6,
      title: "During Your Virtual Visit",
      sections: [
        {
          id: 0,
          name: "During Your Virtual Visit",
          value:
            '<ol  style="list-style-type:circle;list-style-position: inside;"><li>Take time to ask questions about any new or existing treatments while you are with your provider to ensure understanding of your care plan<br/><br/></li><li>You may find it helpful to write down notes during your visit for quick reference after your appointment<br/><br/></li><li>You can access your provider’s notes by logging into your patient portal</li></ol>',
          type: "HTML",
        },
        {
          id: 1,
          name: "After Your Virtual Visit",
          value:
            "If you have questions or need additional assistance after your Virtual Visit has concluded, such as arranging specialist referrals, confirming your pharmacy prescriptions, or clarifying your provider’s instructions, please contact your provider’s office. <br/><br/>",
          type: "HTML",
        },
        {
          id: 2,
          value:
            "<div class='flex justify-between w-1/4 flex-col'><a class='font-semibold bg-brand text-on-brand text-center rounded py-4' target='_blank'  href='https://8042-1.portal.athenahealth.com/'>Patient Portal</a></div>",
          type: "HTML",
        },
      ],
    },
  ],
  tooltip: {
    title: "Need Help?",
    content:
      'Please call your Health First Care Center and a Team Member will assist you. <br/><br/><a href="https://hf.org/hfmg/virtualvisits.cfm?utm_source=HForg&utm_medium=banner_box&utm_campaign=Virtual%20Visits" target="_blank" class = "underline font-bold">Click here to find your Health First Care Center  phone number</a> <br/><br/><a href="https://hf.org/hfmg/virtualvisits.cfm?utm_source=HForg&utm_medium=banner_box&utm_campaign=Virtual%20Visits" target="_blank"  class = "underline font-bold">Click Here to Visit the Health First Website</a>',
  },
  educationalvideo: {
    title: "View Educational Video",
    link: "https://andornow.com/hft-2/techcheck",
  },
};

const content = {
  conviva: defaultData,
  hft: healthFirstData,
};

export const PreVisitState = {
  data: defaultData,
  getData: computed((state) => (tenantKey) => {
    return content[tenantKey];
  }),
};

export default PreVisitState;
