import "./css/videocall.css";
import "./css/bootstrap.min.css";

import { useStoreActions, useStoreState } from "easy-peasy";

import IPad from "./images/ipad.png";
import LanguageMenu from "../../components/ui/localizationMenu";
import LockLine from "./images/lockLine.svg";
import Logo from "./images/andorlogo.png";
import NetPC from "./images/Net-pc.jpg";
import NetSettings from "./images/Net-settings.jpg";
import PopBlock from "./images/popup-block.png";
import PopBlockSpa from "./images/popuo-block-spa.png";
import React from "react";
import Settings from "./images/settings.svg";
import { ShowIf } from "../../components/viewhelpers";
import Signal from "./images/signals.jpg";
import { TechCheckNavBar } from "../../components/ui/general";
import WiFi from "./images/wifi.jpg";
import Windows from "./images/windows.svg";
import useLocalization from "../../hooks/useLocalization";
import { useParams } from "react-router-dom";

const ComPage = () => {
  const [t, changeLanguage] = useLocalization();

  const { tenant } = useParams();

  const { changeCurrentLanguage } = useStoreActions(
    (actions) => actions.languageChangeState
  );
  const { defaultLanguage } = useStoreState(
    (state) => state.languageChangeState
  );
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();

  return (
    <div className="containerBox">
      <TechCheckNavBar
        links={
          <LanguageMenu
            className="cursor-pointer"
            currentLanguage={currentLanguage || defaultLanguage}
            changeCurrentLanguage={changeCurrentLanguage}
            tenantKey={tenant}
          />
        }
        className="mb-2"
      />
      <div className="videoHeader">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="clearfix"></div>
        <div className="pageHead">
          {t("faqPages.pageHead.title")} <br />{" "}
          {t("faqPages.pageHead.subtitle")}
          <br />
          <span>{t("faqPages.pageHead.description.forPCDevices")}</span>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="andorFeatureDataWrapper" id="appleDevices">
        <div className="row usingpc">
          <h2>{t("faqPages.usingPCCameraPermission.title")}</h2>
          <div className="pt-4 col-sm-12 lg:text-xl md:text-base sm:text-xs xs:text-xs">
            <p className="flex pb-4">
              <span className="mr-2">1.</span>
              <span
                className="ml-1 "
                dangerouslySetInnerHTML={{
                  __html: t("faqPages.usingPCCameraPermission.instruction.1.a"),
                }}
              />
              <span>
                <img
                  src={Windows}
                  alt="Windows"
                  className="ml-1 lg:w-8 lg:h-auto md:w-auto md:h-auto sm:w-auto sm:h-auto xs:w-4 xs:h-auto"
                />{" "}
              </span>
              <span className="mx-1 text-black">></span>
              <span className="">
                <b>{t("faqPages.usingPCCameraPermission.instruction.1.b")}</b>
              </span>
              <span>
                <img
                  src={Settings}
                  alt="Settings"
                  className="ml-1 lg:w-8 lg:h-auto md:w-auto md:h-auto sm:w-auto sm:h-auto xs:w-4 xs:h-auto"
                />{" "}
              </span>
              <span className="mx-1 text-black">></span>
              <span>
                <b>{t("faqPages.usingPCCameraPermission.instruction.1.c")}</b>
              </span>
              <span>
                <img
                  src={LockLine}
                  alt="Settings"
                  className="ml-1 lg:w-8 lg:h-auto md:w-auto md:h-auto sm:w-auto sm:h-auto xs:w-4 xs:h-auto"
                />{" "}
              </span>
              <span className="mx-1 text-black">></span>
              <span>
                <b>{t("faqPages.usingPCCameraPermission.instruction.1.d")}</b>
              </span>
            </p>
            <p className="flex pb-4">
              <span className="mr-2">2.</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("faqPages.usingPCCameraPermission.instruction.2"),
                }}
              />
            </p>
            <p className="flex ">
              <span className="mr-2">3.</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("faqPages.usingPCCameraPermission.instruction.3"),
                }}
              />
            </p>
          </div>
        </div>
        <div className="pt-5 row usingpc">
          <h2>{t("faqPages.usingPCMicrophonePermission.title")}</h2>
          <div className="pt-4 col-sm-12 lg:text-xl md:text-base sm:text-xs xs:text-xs">
            <p className="flex pb-4">
              <span className="mr-2">1.</span>
              <span
                className="ml-1 "
                dangerouslySetInnerHTML={{
                  __html: t(
                    "faqPages.usingPCMicrophonePermission.instruction.1.a"
                  ),
                }}
              />
              <span>
                <img
                  src={Windows}
                  alt="Windows"
                  className="ml-1 lg:w-8 lg:h-auto md:w-auto md:h-auto sm:w-auto sm:h-auto xs:w-4 xs:h-auto"
                />{" "}
              </span>
              <span className="mx-1 text-black">></span>
              <span className="">
                <b>
                  {t("faqPages.usingPCMicrophonePermission.instruction.1.b")}
                </b>
              </span>
              <span>
                <img
                  src={Settings}
                  alt="Settings"
                  className="ml-1 lg:w-8 lg:h-auto md:w-auto md:h-auto sm:w-auto sm:h-auto xs:w-4 xs:h-auto"
                />{" "}
              </span>
              <span className="mx-1 text-black">></span>
              <span>
                <b>
                  {t("faqPages.usingPCMicrophonePermission.instruction.1.c")}
                </b>
              </span>
              <span>
                <img
                  src={LockLine}
                  alt="Settings"
                  className="ml-1 lg:w-8 lg:h-auto md:w-auto md:h-auto sm:w-auto sm:h-auto xs:w-4 xs:h-auto"
                />{" "}
              </span>
              <span className="mx-1 text-black">></span>
              <span>
                <b>
                  {t("faqPages.usingPCMicrophonePermission.instruction.1.d")}
                </b>
              </span>
            </p>
            <p className="flex pb-4">
              <span className="mr-2">2.</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    "faqPages.usingPCMicrophonePermission.instruction.2"
                  ),
                }}
              />
            </p>
            <p className="flex ">
              <span className="mr-2">3.</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    "faqPages.usingPCMicrophonePermission.instruction.3"
                  ),
                }}
              />
            </p>
          </div>
        </div>
        <div className="pt-5 row usingpc ipad">
          <h2>{t("faqPages.onCallPermissions")}</h2>
          <div className="pt-4 col-sm-12">
            <img src={IPad} alt="IPad" />
          </div>
        </div>
        <div className="pt-5 row usingpc">
          <h2>{t("faqPages.popUpPermissions")}</h2>
          <div className="pt-4 col-sm-12">
            <ShowIf test={currentLanguage === "en-es"}>
              <img src={PopBlockSpa} alt="popup block" />
            </ShowIf>
            <ShowIf
              test={currentLanguage === "en-us" || currentLanguage === "en-bis"}
            >
              <img src={PopBlock} alt="popup block" />
            </ShowIf>
          </div>
        </div>
        <div className="pt-5 row usingpc">
          <h2>{t("faqPages.browsersSupports.title")}</h2>
          <div className="pt-2 pl-0 col-sm-12">
            <p>{t("faqPages.browsersSupports.subTitle")}</p>
          </div>
        </div>
        <div className="pt-5 row usingpc">
          <div className="pt-4 pl-0 col-sm-12">
            <h2>{t("faqPages.browsersSupports.tableTitle")}</h2>
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: "#f0eff4" }}>
                  <tr>
                    <th></th>
                    <th>Windows</th>
                    <th>macOS</th>
                    <th>Ubuntu</th>
                    <th>Linux</th>
                    <th>Android</th>
                    <th>iOS</th>
                    <th>iPad OS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {t("faqPages.browsersSupports.tableContentRow.rowName")}
                    </td>
                    <td>
                      Chrome*,
                      <br />{" "}
                      {t(
                        "faqPages.browsersSupports.tableContentRow.browsers.newEdge"
                      )}
                    </td>
                    <td>
                      Chrome*,
                      <br /> Safari**
                    </td>
                    <td>Chrome*</td>
                    <td>Chrome*</td>
                    <td>Chrome*</td>
                    <td>Safari**</td>
                    <td>Safari**</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>{t("faqPages.browsersSupports.browserSupportNotes.first")}</p>
            <p>{t("faqPages.browsersSupports.browserSupportNotes.second")}</p>
            <p>{t("faqPages.browsersSupports.browserSupportNotes.third")}</p>
            <b>{t("faqPages.connectivityContent.title")}</b>
            <p>{t("faqPages.connectivityContent.first")}</p>
            <p>{t("faqPages.connectivityContent.second")}</p>
          </div>
        </div>
        <div className="pt-5 row usingpc brouseCompat">
          <div className="row">
            <div className="col-sm-12 col-md-1">
              <img src={NetPC} alt="Net pc" />
            </div>
            <div className="col-sm-12 col-md-9">
              <h3>{t("faqPages.requiredChecks.checkBrowser.title")}</h3>
              <p>{t("faqPages.requiredChecks.checkBrowser.content")}</p>
            </div>
          </div>
          <div className="row">
            <div className="pt-2 col-sm-12 col-md-1">
              <img src={WiFi} alt="wifi" />
            </div>
            <div className="col-sm-12 col-md-9">
              <h3>{t("faqPages.requiredChecks.anotherNetwork.title")}</h3>
              <p>{t("faqPages.requiredChecks.anotherNetwork.content")}</p>
            </div>
          </div>
          <div className="row">
            <div className="pt-2 col-sm-12 col-md-1">
              <img src={Signal} alt="signal" />
            </div>
            <div className="col-sm-12 col-md-9">
              <h3>{t("faqPages.requiredChecks.checkSignal.title")}</h3>
              <p>
                {t("faqPages.requiredChecks.checkSignal.content")}
                <span style={{ visibility: "hidden" }}>
                  {" "}
                  your Wi-Fi signal is poor, positioning yourself close to your
                  router may improve signal.{" "}
                </span>{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="pt-2 col-sm-12 col-md-1">
              <img src={NetSettings} alt="Net Settings" />
            </div>
            <div className="col-sm-12 col-md-9">
              <h3>{t("faqPages.requiredChecks.devicesDisconnection.title")}</h3>
              <p>{t("faqPages.requiredChecks.devicesDisconnection.content")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComPage;
