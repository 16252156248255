import React from "react";
import { BaseScreen } from "components/ui/general";
import TenantTheme from "components/TenantTheme";
import useLocalization from "hooks/useLocalization";
import DisplayCollapse from "./DisplayCollapse";

const Faqs = ({ tenantKey }) => {
  const [t] = useLocalization();

  const collapseContent = {
    id: 1,
    caption: "Click on one of the sections below to learn more!",
    value: [
      {
        id: 0,
        name: t("techCheckValidateDevice.faqAppDownload"),
        content: t("techCheckValidateDevice.ansAppDownload"),
      },
      {
        id: 1,
        name: t("techCheckValidateDevice.faqScheduleVisit"),
        content: t("techCheckValidateDevice.ansScheduleVisit"),
      },
      {
        id: 2,
        name: t("techCheckValidateDevice.faqInviteParticipant"),
        content: t("techCheckValidateDevice.ansInviteParticipant"),
      },
    ],
  };

  const collapseContentForMusc = {
    id: 1,
    caption: "Click on one of the sections below to learn more!",
    value: [
      {
        id: 0,
        name: t("techCheckValidateDevice.faqAppDownload"),
        content: t("techCheckValidateDevice.ansAppDownload"),
      },
      {
        id: 1,
        name: t("techCheckValidateDevice.faqScheduleVisit"),
        content: t("techCheckValidateDevice.ansScheduleVisit"),
      },
      {
        id: 2,
        name: t("techCheckValidateDevice.faqInviteParticipant"),
        content: t("techCheckValidateDevice.ansInviteParticipant"),
      },
      {
        id: 3,
        name: t("techCheckValidateDevice.faqNotificationAppointment"),
        content: t("techCheckValidateDevice.ansNotificationAppointment"),
      },
    ],
  };

  return (
    <TenantTheme>
      <BaseScreen className="flex items-center bg-card px-3">
        <div className="w-full py-6 max-w-3xl">
          <DisplayCollapse
            value={
              tenantKey === "musc"
                ? collapseContentForMusc.value
                : collapseContent.value
            }
          />
        </div>
      </BaseScreen>
    </TenantTheme>
  );
};

export default Faqs;
