import { useTranslation } from "react-i18next";

export const useLocalization = (props) => {
  const { t, i18n } = useTranslation();

  const text = (value) => t(value);
  const changeLanguage = (lng) => i18n.changeLanguage(lng);
  return [text, changeLanguage];
};

export default useLocalization;
