export const PrimaryLightColor = "#303030";
export const PrimaryDarkColor = "#FFFFFF";

export const PrimaryMediumColor = "#19b393";
export const PrimaryMediumColorLight = "#303030";
export const PrimaryMediumColorDark = "#FFFFFF";

export const SecondaryLightColor = "#93D43B";
export const SecondaryMediumColor = "#F18519";
export const SecondaryDarkColor = "#B8BDC1";

export const SubtleColor = "#868686";

export const ErrorColor = "#d32f2f";
export const WarningColor = "#ffa000";
export const InfoColor = "#1976d2";
export const SuccessColor = "#43a047";

export const navy = "#001F3F";
export const blue = "#0074D9";
export const aqua = "#7FDBFF";
export const teal = "#39CCCC";
export const olive = "#3D9970";
export const green = "#2ECC40";
export const lime = "#01FF70";
export const yellow = "#FFDC00";
export const orange = "#FF851B";
export const red = "#FF4136";
export const fuchsia = "#F012BE";
export const purple = "#B10DC9";
export const maroon = "#85144B";
export const white = "#FFFFFF";
export const silver = "#DDDDDD";
export const gray = "#AAAAAA";
export const black = "#111111";
