import * as yup from "yup";

const phoneRegExp = /^\+(?:[0-9] ?){10}[0-9]$/i;

export const phoneValidation = yup
  .string()
  .matches(phoneRegExp, "Phone number is not valid");

export const faxValidation = yup
  .string()
  .matches(phoneRegExp, "Fax number is not valid");

export const emailValidation = yup.string().email("Email is not valid");

export const textValidation = yup.string();
export const datetimeValidation = yup.string();
export const selectValidation = yup.string();
export const checkboxValidation = yup.boolean().nullable();

export const setRequired = (type, yupObj) => {
  switch (type) {
    case "checkbox":
      return yupObj.oneOf([true]).required();
    default:
      return yupObj.required();
  }
};

export const validations = {
  radio: yup.string(),
  textarea: yup.string(),
  date: yup.string(),
  options: yup.string(),
  default: yup.string(),
};

export const getValidations = (formItems = [], key = "id", type = "type") => {
  const validationObject = formItems.reduce((acc, item) => {
    const validationType = validations[item[type]] ? item[type] : "default";
    acc[item[key]] =
      item.isRequired && item.isVisible
        ? validations[validationType].required()
        : validations[validationType];
    return acc;
  }, {});
  const schema = yup.object().shape(validationObject);
  return schema;
};

export const inviteNewParticipantSchema = yup.object().shape(
  {
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    relationship: yup.string().required(),
    email: emailValidation.when("phone", {
      is: (val) => !val,
      then: emailValidation.required(),
      otherwise: yup.string(),
    }),
    phone: phoneValidation.when("email", {
      is: (val) => !val,
      then: phoneValidation.required(),
      otherwise: yup.string(),
    }),
  },

  ["email", "phone"]
);
