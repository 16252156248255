import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "whiteboard/AppState";
import Dialog from "@mui/material/Dialog";
import { BaseScreen } from "components/homepage/General";
import { IncompatibleDeviceFaq } from "./components/IncopatibleDeviceFaq";
import LanguageMenu from "components/ui/localizationMenu";
import RenderTechCheckView from "./components/RenderTechCheckView";
import { TechCheckNavBar } from "components/ui/general";
import TechCheckResult from "./components/TechCheckResult";
import TenantLogo from "../../components/ui/logo";
import { dynamicText } from "../../utils/StringUtils";
import { updateSupportedDevice } from "services/PlatformService";
import useLink from "../../hooks/useLink";
import useLocalization from "hooks/useLocalization";
import { validateDevice } from "./utils";
import { isMobileOnly } from "react-device-detect";
import { ShowIf } from "components/viewhelpers";
import { StyledModalPopper } from "../../components/InviteParticipantForm/styling";
import InviteParticipantsForms from "../../components/InviteParticipantForm/InviteParticipantsForms";
import ConfirmationModal from "../../components/InviteParticipantForm/ConfirmationModal";
import InviteParticipantButton from "../../components/InviteParticipantForm/InviteParticipantButton";
import InfoBanner from "./components/InfoBanner";
import AdditionalInfoSection from "./components/AdditionalInfoSection";
import Faqs from "./components/FAQ/index";
import TytocareInfo from "./components/TytoCare/TytocareInfo";
import { TransitionUp } from "../ModalPopper";

const ValidateDevice = ({ tenantKey }) => {
  const { tenant } = useParams();
  const [link] = useLink();
  const uriCode = window.location.search.replace("?uriCode=", "");
  const [t] = useLocalization();
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [inviteParticipant, setInviteParticipant] = useState(false);
  const [participantIsConfirmed, setParticipantIsConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showTytocareModal, setShowTytocareModal] = useState(false);
  const tenantCode = !!tenant ? tenant.toLowerCase() : tenantKey ?? "";
  const parts = tenantCode.split("-");
  const { changeCurrentLanguage } = useStoreActions(
    (actions) => actions.languageChangeState
  );
  const { defaultLanguage } = useStoreState(
    (state) => state.languageChangeState
  );
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const { getTenantBrandingViewConfig } = useStoreActions(
    (store) => store.tenantBrandingState
  );
  const {
    isFaqSectionEnabled,
    isInviteParticipantEnabled,
    homePageUrl,
    pageHeader,
    pageMiddle,
    actionButton,
    tytocareInfoButton,
    tytocareInfoBanner,
  } = useStoreState((store) => store.tenantBrandingState);
  const { scheduledAppointments } = useStoreState(
    (state) => state.techCheckState
  );
  const { getScheduledAppointments, postProviderListInvitees } =
    useStoreActions((actions) => actions.techCheckState);

  const dateValue = "${appointmentDateTime|date}";
  const appointmentDate = scheduledAppointments?.appointments
    ? dynamicText(scheduledAppointments.appointments[0], dateValue, "")
    : "";
  const getCurrentLanguage = useCallback(() => {
    switch (currentLanguage) {
      case "en":
        return changeCurrentLanguage("en-us");
      case "es":
        return changeCurrentLanguage("en-es");
      case "bis":
        return changeCurrentLanguage("en-bis");
      default:
        return;
    }
  }, [currentLanguage]);
  useEffect(() => {
    const performInit = async () => {
      await getTenantBrandingViewConfig({
        tenantKey: parts[0],
        orgSite: parts[1],
        name: "GLOBAL_TECH_CHECK_VIEW_V2",
      });
      uriCode && (await getScheduledAppointments({ uriCode }));
      const { isBrowserSupported, isOSSupported } = await validateDevice();
      uriCode &&
        (await updateSupportedDevice({
          IsBrowserSupported: isBrowserSupported,
          IsDeviceSupported: isOSSupported,
          uriCode: uriCode,
        }));
    };
    getCurrentLanguage();
    performInit();
  }, []);

  const handleInvite = () => {
    setInviteParticipant(true);
  };
  const onCancel = () => {
    setInviteParticipant(false);
  };

  const onSendInvite = async (data) => {
    try {
      const res = await postProviderListInvitees({
        tenantKey: tenantKey,
        uriCode: uriCode,
        meetingType: "VISIT",
        participants: [...data?.participants],
      });
      if (res?.isSuccess) {
        setInviteParticipant(false);
        setParticipantIsConfirmed(true);
      } else if (!res?.isSuccess) {
        setErrorMessage("Something went wrong. Please try later!");
        setTimeout(() => setErrorMessage(""), 5000);
      }
    } catch (e) {
      setErrorMessage("Something went wrong. Please try later!");
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  return (
    <BaseScreen>
      <div className="flex justify-center mb-3 py-6 shadow-md">
        <TenantLogo onClick={link("/")} className="cursor-pointer" />
      </div>
      <ShowIf test={pageHeader && pageHeader.length > 0}>
        <InfoBanner
          pageHeader={pageHeader}
          currentLanguage={defaultLanguage}
          message={t("techCheckValidateDevice.muscMessage")}
        />
      </ShowIf>
      <div className="flex flex-col">
        <TechCheckNavBar
          links={
            <LanguageMenu
              className="cursor-pointer"
              currentLanguage={defaultLanguage}
              changeCurrentLanguage={changeCurrentLanguage}
              tenantKey={tenant ?? tenantKey}
            />
          }
        />
      </div>
      <RenderTechCheckView tenantKey={tenant ?? tenantKey}>
        <TechCheckResult
          setIsOpenModal={setShowFaqModal}
          scheduledAppointments={scheduledAppointments}
          tenantKey={tenant ?? tenantKey}
          appointmentDate={appointmentDate}
          currentLanguage={defaultLanguage}
        />
        <ShowIf test={isInviteParticipantEnabled}>
          <InviteParticipantButton handleInvite={handleInvite} />
        </ShowIf>

        <ShowIf test={homePageUrl || pageMiddle}>
          <AdditionalInfoSection
            currentLanguage={defaultLanguage}
            homePageUrl={homePageUrl}
            pageMiddle={pageMiddle}
            actionButton={actionButton}
          />
        </ShowIf>
        <ShowIf test={!!tytocareInfoBanner || !!tytocareInfoButton}>
          <AdditionalInfoSection
            currentLanguage={defaultLanguage}
            pageMiddle={tytocareInfoBanner}
            actionButton={tytocareInfoButton}
            openModal={() => setShowTytocareModal(!showTytocareModal)}
          />
        </ShowIf>
        <ShowIf test={isFaqSectionEnabled}>
          <div className="py-16 w-full">
            <div className="text-2xl sm:text-4xl font-bold text-center">
              {t("techCheckValidateDevice.faq")}
            </div>
            <Faqs tenantKey={tenant ?? tenantKey} />
          </div>
        </ShowIf>
      </RenderTechCheckView>
      <Dialog
        open={showFaqModal}
        onClose={() => setShowFaqModal(false)}
        fullScreen
      >
        <IncompatibleDeviceFaq setIsOpen={setShowFaqModal} />
      </Dialog>
      <Dialog
        open={showTytocareModal}
        onClose={() => setShowTytocareModal(!showTytocareModal)}
        fullScreen
      >
        <TytocareInfo setIsOpen={setShowTytocareModal} />
      </Dialog>
      <StyledModalPopper
        width={isMobileOnly ? "xs" : "sm"}
        onClose={onCancel}
        TransitionComponent={TransitionUp}
        open={inviteParticipant}
        ismobileonly={isMobileOnly.toString()}
      >
        <InviteParticipantsForms
          onCancel={onCancel}
          onSendInvite={onSendInvite}
          uriCode={uriCode}
          tenantKey={tenantKey}
          errorMessage={errorMessage}
        />
      </StyledModalPopper>
      <StyledModalPopper
        width={isMobileOnly ? "xs" : "sm"}
        open={participantIsConfirmed}
        onClose={() => setParticipantIsConfirmed(false)}
        TransitionComponent={TransitionUp}
        ismobileonly={isMobileOnly.toString()}
      >
        <ConfirmationModal
          setParticipantIsConfirmed={setParticipantIsConfirmed}
        />
      </StyledModalPopper>
    </BaseScreen>
  );
};
export default ValidateDevice;

ValidateDevice.propTypes = {
  tenantKey: PropTypes.string,
};

ValidateDevice.defaultProps = {
  tenantKey: "",
};
