import React from "react";

export default ({ className, ...props }) => {
  return (
    <div
      className={`relative app-logo w-32 h-5 bg-no-repeat bg-center ${className}`}
      {...props}
    />
  );
};
