import React from "react";
import {
  ArrowTipText,
  ArrowTipLink,
  ArrowTipImage,
  ArrowTipContainer,
} from "./styling";

const ArrowTip = ({ url, children }) => {
  return (
    <ArrowTipContainer>
      <ArrowTipImage />
      {!!url ? (
        <ArrowTipLink href={url}>{children}</ArrowTipLink>
      ) : (
        <ArrowTipText>{children}</ArrowTipText>
      )}
    </ArrowTipContainer>
  );
};

export default ArrowTip;
