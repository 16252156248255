import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button } from "components/ui/buttons";
import { ShowIf } from "components/viewhelpers";

const AdditionalInfoSection = ({
  currentLanguage,
  homePageUrl,
  pageMiddle,
  actionButton,
  openModal,
}) => {
  const [infoMessage, setInfoText] = useState("");
  const [buttonText, setButtonText] = useState("Click Here");

  const getInfoText = useCallback(
    (language) => {
      const requestedHeader = pageMiddle.find(
        (header) =>
          header?.languageCode?.toLowerCase() === language?.toLowerCase()
      );
      return requestedHeader?.message;
    },
    [currentLanguage]
  );
  const getButtonText = useCallback(
    (language) => {
      const requestedHeader = actionButton.find(
        (header) =>
          header?.languageCode?.toLowerCase() === language?.toLowerCase()
      );
      return requestedHeader?.text;
    },
    [currentLanguage]
  );
  useEffect(() => {
    const infoMessage = getInfoText(currentLanguage);
    setInfoText(infoMessage);
    const buttonText = getButtonText(currentLanguage);
    setButtonText(buttonText);
  }, [currentLanguage]);

  const onClick = () => {
    if (!!homePageUrl) window.open(homePageUrl, "_blank");
    if (!!openModal) openModal();
  };
  return (
    <div className="flex flex-col gap-2 items-center justify-center w-full font-medium text-white text-lg sm:text-xl p-4 bg-[#1D8187] border-[1px] border-solid border-white">
      <span className="mb-2 max-w-3xl">{infoMessage}</span>
      <ShowIf test={actionButton && actionButton.length > 0}>
        <Button
          className="border-2 border-white ring-0 bg-transparent text-center text-sm w-full sm:w-fit px-5"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </ShowIf>
    </div>
  );
};

export default AdditionalInfoSection;
AdditionalInfoSection.propTypes = {
  currentLanguage: PropTypes.string,
  homePageUrl: PropTypes.string,
  pageMiddle: PropTypes.array,
  actionButton: PropTypes.array,
};
