import React, { useState } from "react";
import styled from "styled-components";
import { PhoneStyled, getShared } from "./styling";
import noop from "lodash/noop";
import classnames from "classnames";

const InputContainer = styled.div(
  ({ isInputFocused }) => `
    box-shadow: ${isInputFocused ? "0 0 0 3px rgba(66, 153, 225, 0.5)" : ""};
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    outline: none;
`
);

export default (props) => {
  const {
    error,
    onChange = noop,
    defaultCountry,
    border = "border",
    className,
    isgreybackground = false,
    ...inputProps
  } = props;
  const [isInputFocused, setIsInputFocused] = useState(false);
  const shared = getShared(props);
  const value = props.value || "";
  const _defaultCountry = defaultCountry || "US";

  const toggleFocus = () => setIsInputFocused(true);
  const toggleUnFocus = () => setIsInputFocused(false);

  return (
    <InputContainer isInputFocused={isInputFocused} onBlur={toggleUnFocus}>
      <PhoneStyled
        country={_defaultCountry}
        defaultCountry={_defaultCountry}
        onChange={(val) => {
          val = val || "";
          onChange(val);
        }}
        onFocus={toggleFocus}
        countryOptionsOrder={["US", "CA", "..."]}
        className={classnames(
          `bg-card disabled:opacity-50  appearance-none ${border} ${shared} shadow-md rounded pl-3 text-on-card ${className}`
        )}
        {...inputProps}
        value={value}
        isgreybackground={isgreybackground.toString()}
      />
    </InputContainer>
  );
};
