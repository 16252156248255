import isString from "lodash/isString";
import mapValues from "lodash/mapValues";
import { templateWithFormatting } from "utils/TemplateUtils";

export const dynamicText = (data, str = "", defaultValue) => {
  try {
    if (str.indexOf("$") >= 0) {
      return templateWithFormatting(str, data);
    }
    return str;
  } catch (e) {
    return defaultValue ?? str;
  }
};

export const dynamicTextOnObject = (data, obj) => {
  return mapValues(obj, (value, key, object) => {
    if (isString(value) && value.indexOf("$") >= 0) {
      return dynamicText(data, value);
    }
    return value;
  });
};
