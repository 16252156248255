import { action, thunk } from "easy-peasy";

import assign from "lodash/assign";
import {
  getScheduledAppointments,
  getRelationshipTypes,
  inviteParticipant,
} from "../services/PlatformService";

export const TechCheckState = {
  scheduledAppointments: {},
  relationshipListWebRTC: [],
  relationshipList: [],
  getScheduledAppointments: thunk(async (actions, { uriCode }) => {
    const response = await getScheduledAppointments({ uriCode });
    actions.setData({ scheduledAppointments: response });
  }),
  setData: action((state, data) => {
    assign(state, data);
  }),
  getRelationshipListWebRtc: thunk(async (actions, payload, helpers) => {
    const result = await getRelationshipTypes(payload.uriCode);
    const relationshipListWebRTC = result.data || [];
    actions.setData({ relationshipListWebRTC });
    return relationshipListWebRTC;
  }),
  getRelationshipList: thunk(async (actions, payload, helpers) => {
    const result = await getRelationshipTypes(payload);
    const relationshipList = result || [];
    actions.setData({ relationshipList });
    return relationshipList;
  }),
  postProviderListInvitees: thunk(async (actions, payload, helpers) => {
    const result = await inviteParticipant(payload);
    return result;
  }),
};
