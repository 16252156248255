import React, { useEffect, useState } from "react";

import { getRedirectURL } from "../services/PlatformService";
import { useParams } from "react-router";

const UriCodeRedirect = () => {
  const { uriCode } = useParams();
  const [redirectURL, setRedirectURL] = useState("");

  useEffect(() => {
    async function getPath() {
      const path = await getRedirectURL(uriCode);
      setRedirectURL(path.redirectUrl);
      window.location.href = path.redirectUrl;
    }
    if (!redirectURL) {
      getPath();
    }
  }, [redirectURL, uriCode]);

  return <></>;
};

export default UriCodeRedirect;
