import {
  OptionRoot,
  RadioInput,
  RadioInputSpan,
  RadioInputText,
} from "./styling";

import React from "react";
import { sanitize } from "dompurify";

export default ({
  className,
  value = "",
  label,
  name,
  id,
  onChange,
  selected,
  error,
  ...props
}) => (
  <OptionRoot className={`flex items-center ${className}`} {...props}>
    <label className="relative flex items-center mt-2 cursor-pointer">
      <RadioInput
        type="radio"
        name={name}
        className="w-0 h-0"
        value={value}
        onChange={onChange}
        checked={selected === value}
        onClick={onChange}
      />

      <RadioInputSpan error={error} />
      <RadioInputText
        className="pl-5 ml-2 font-medium text-on-card "
        dangerouslySetInnerHTML={{
          __html: sanitize(label || value, { ADD_ATTR: ["target"] }),
        }}
      />
    </label>
  </OptionRoot>
);
