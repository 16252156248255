import moment from "moment";
import replace from "lodash/replace";

export const convertDateToString = (dateObj, format = "YYYY-MM-DD") => {
  const str = moment(dateObj).format(format);
  return str;
};

export const convertDateToAge = (dateObj) => {
  const years = moment().diff(dateObj, "years", false);
  const months = moment().diff(dateObj, "months", false);
  const days = moment().diff(dateObj, "days", false);
  return years > 0
    ? `${years} years`
    : months > 0
    ? `${months} months`
    : `${days} days`;
};

export const getTodayAsString = () => {
  return convertDateToString(new Date());
};

export const getTodayHours = () => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format("h:mm A"));
  }
  return hours;
};

export const getTimeDifference = (dateObj) => {
  const now = moment(new Date());
  const toDate = moment(dateObj);
  const duration = moment.duration(now.diff(toDate));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return days > 0
    ? `${days} Day`
    : hours > 0
    ? `${hours} Hrs`
    : minutes > 0
    ? `${minutes} Min`
    : seconds > 0
    ? `${seconds} Sec`
    : "Now";
};

export const getReadableFormatfromDifference = (difference) => {
  const duration = moment.duration(difference);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return days > 0
    ? `${days} Day`
    : hours > 0
    ? `${hours} Hrs`
    : minutes > 0
    ? `${minutes} Min`
    : seconds > 0
    ? `${seconds} Sec`
    : "Now";
};

export const getTimeDifferenceInMinutes = (dateObj) => {
  const now = moment(new Date());
  const toDate = moment(dateObj);
  const duration = moment.duration(now.diff(toDate));
  const minutes = duration.asMinutes();
  return minutes;
};

export const getLocaleDateFormat = () => {
  let dateStr = new Date("2020/10/20").toLocaleDateString();
  let dateFormat = replace(dateStr, "10", "MM");
  dateFormat = replace(dateFormat, "20", "DD");
  dateFormat = replace(dateFormat, "2020", "YYYY");
  return dateFormat;
};
