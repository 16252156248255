import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Form, Formik } from "formik";
import shortid from "shortid";
import { inviteNewParticipantSchema } from "utils/validations";
import { Group, Input, Label, Phone, Select } from "components/Form_Utilities";
import { ThinkAndorButton } from "components/ui/buttons";
import classnames from "classnames";
import { isMobileOnly } from "react-device-detect";
import useLocalization from "hooks/useLocalization";

const ParticipantForm = ({ relationList, setRenderView, dispatch }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    relationshipName: "",
    email: "",
    phone: "",
    languageCode: "en-US",
  };

  const onCancel = (e) => {
    e.preventDefault();
    setRenderView("homeView");
  };
  const [t, changeLanguage] = useLocalization();

  return (
    <div className="px-2 py-4">
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(
          { firstName, lastName, relationship, email, phone },
          { resetForm }
        ) => {
          const value = {
            id: shortid.generate(),
            firstName,
            relationship,
            lastName,
            email,
            phone,
          };
          dispatch({ type: "ADD", key: "participants", value });
          resetForm();
          setRenderView("homeView");
        }}
        validationSchema={inviteNewParticipantSchema}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <Group>
              <Label>{t("techCheckValidateDevice.firstName")}</Label>
              <Input
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={noop}
                error={errors.firstName}
                className="bg-newGray-150"
                inviteparticipant={"true"}
              />
            </Group>
            <Group>
              <Label>{t("techCheckValidateDevice.lastName")}</Label>
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={noop}
                error={errors.lastName}
                className="bg-newGray-150"
                inviteparticipant={"true"}
              />
            </Group>
            <Group>
              <Label>{t("techCheckValidateDevice.relationship")}</Label>

              <Select
                name="relationship"
                value={values.relationship}
                onChange={handleChange}
                onBlur={noop}
                error={errors.relationship}
                className="bg-newGray-150"
                inviteparticipant={"true"}
              >
                <option value="" disabled hidden>
                  {t("techCheckValidateDevice.select")}
                </option>
                {relationList?.map((relation, index) => {
                  return (
                    <option key={index} value={relation}>
                      {relation}
                    </option>
                  );
                })}
              </Select>
            </Group>
            <Group>
              <Label>{t("techCheckValidateDevice.email")}</Label>
              <Input
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={noop}
                error={errors.email}
                className="bg-newGray-150"
                inviteparticipant={"true"}
              />
            </Group>
            <Group>
              <Label>{t("techCheckValidateDevice.phoneNumber")}</Label>
              <Phone
                name="phone"
                value={values.phone}
                onChange={(value) => {
                  setFieldValue("phone", value);
                }}
                onBlur={noop}
                error={errors.phone}
                className="bg-newGray-150"
                inviteparticipant={"true"}
                isgreybackground={true}
              />
            </Group>
            <div
              className={classnames("flex items-center justify-end px-2 py-4", {
                "flex-col justify-center": isMobileOnly,
              })}
            >
              <ThinkAndorButton
                className={classnames(
                  "flex justify-center text-center text-sm bg-gray-700 mr-2 font-medium",
                  {
                    "w-full m-1": isMobileOnly,
                  }
                )}
                isShowLastMargin={!isMobileOnly}
                onClick={(e) => onCancel(e)}
                hover={false}
              >
                {t("techCheckValidateDevice.cancel")}
              </ThinkAndorButton>
              <ThinkAndorButton
                className={classnames(
                  "flex justify-center text-center text-sm font-medium px-6",
                  {
                    "w-full m-1": isMobileOnly,
                  }
                )}
                isShowLastMargin={!isMobileOnly}
                type="submit"
                hover={true}
                style={{ minWidth: 83, height: 36 }}
              >
                {t("techCheckValidateDevice.add")}
              </ThinkAndorButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ParticipantForm;

ParticipantForm.propType = {
  relationList: PropTypes.array,
  setRenderView: PropTypes.func,
  dispatch: PropTypes.func,
};
